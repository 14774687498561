import useSWR from "swr";
import { BannerNew } from "./components/BannerNew";
import { bannerAPI } from "services/banners";
import { Banner } from "./components/Banner";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Banners = () => {
  const {
    data: banners,
    isLoading,
    mutate: fetchBanners,
  } = useSWR("banners", () => bannerAPI.getBanners());

  const handleSuccess = () => {
    fetchBanners();
  };

  return (
    <>
      {isLoading && (
        <div className="flex items-center justify-center py-4">
          <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
        </div>
      )}
      <div className="mt-3 grid h-full grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-3">
        {!isLoading && <BannerNew onSuccess={handleSuccess} />}
        {!!banners &&
          banners.length > 0 &&
          banners.sort((bannerA, bannerB) => Number(bannerB.isActive) - Number(bannerA.isActive)).map((banner, i) => (
            <Banner key={banner.id} banner={banner} onSuccess={handleSuccess} />
          ))}
      </div>
    </>
  );
};

export default Banners;
