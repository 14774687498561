import { Navigate } from "react-router-dom";
import { getAccessToken } from "utils/auth";

export const PrivateRoute = ({ children }: {children: JSX.Element}) => {
  const hasToken = () => {
    try {
      const token = getAccessToken();
      return !!token;
    } catch (error) {
      return false;
    }
  }

  if (!hasToken()) {
    return <Navigate to="/auth" replace />;
  }

  return children;
};
