import * as Dialog from "@radix-ui/react-dialog";
import { DangerButton } from "components/Button/Danger";
import { ButtonSM } from "components/Button/Small";
import { SuccessButton } from "components/Button/Success";
import { TOKEN_NAME } from "constants/token";
import { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { analyticsAPI } from "services/analytics";
import { usersAPI } from "services/user";
import useSWR from "swr";
import { toFixed } from "utils/number";

interface UserDialogProps {
  userId: string;
  onOpenChange: (isOpen: boolean) => void;
}

export function UserDialog({ userId, onOpenChange }: UserDialogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { data: user, isLoading, mutate } = useSWR(
    isOpen ? `/user${userId}` : null,
    () => analyticsAPI.getUserData(userId)
  );
  const [isSubmitting, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleBlockOrUnblock = async (block: boolean) => {
    setError(null);
    setIsLoading(true);

    if (!window.confirm(`Are you sure you want to ${block ? "block" : "unblock"} this user?`)) {
      return;
    }

    try {
      await usersAPI.setUserIsKicked(userId, block);
      await mutate();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog.Root
      modal
      open={isOpen}
      onOpenChange={(isOpen) => {
        setIsOpen(isOpen);
        onOpenChange(isOpen);
      }}
    >
      <Dialog.Trigger asChild>
        <ButtonSM>Profile</ButtonSM>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[650px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-gray-800 dark:text-white">
          <Dialog.Title className="text-mauve12 mb-10 text-2xl font-medium">
            User profile
          </Dialog.Title>
          {isLoading && (
            <div className="flex h-[292px] items-center justify-center">
              <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
            </div>
          )}
          {!isLoading && user && (
            <>
              <div className="max-h-[780px] grow overflow-x-auto px-px">
                <ul className="flex flex-col gap-4 text-lg">
                  <li>
                    <b>Total amount of tokens:</b> {toFixed(user.totalWorth, 2)}{" "}
                    <span className="text-base text-gray-500">
                      {TOKEN_NAME}
                    </span>
                  </li>
                  <li>
                    <b>Total wallet worth:</b> {toFixed(user.totalWorthUsd, 2)}{" "}
                    <span className="text-base text-gray-500">USD</span>
                  </li>
                  <li>
                    <b>Wallet address:</b> {user.wallet}
                  </li>
                  <li>
                    <b>Email:</b> {user.email}
                  </li>
                  <li>
                    <b>Bonus amount:</b> {user.totalBonuses}{" "}
                    <span className="text-base text-gray-500">{TOKEN_NAME}</span>
                  </li>
                  <li>
                    <b>Referral (email):</b>{" "}
                    {user.referrerEmail ? (
                      user.referrerEmail
                    ) : (
                      <span className="text-gray-500">No referrer</span>
                    )}
                  </li>
                  <li>
                    <b>Referral code:</b>{" "}
                    {!!user.referralCode ? (
                      user.referralCode.code
                    ) : (
                      <span className="text-gray-500">No referral code</span>
                    )}
                  </li>
                </ul>
                <div className="mt-4 grid grid-cols-2 gap-4">
                  {user.kicked && (
                    <SuccessButton
                      disabled={isSubmitting}
                      className="justify-center"
                      onClick={() => handleBlockOrUnblock(false)}
                    >
                      {isSubmitting ? "Loading..." : "Unblock"}
                    </SuccessButton>
                  )}
                  {!user.kicked && (
                    <DangerButton
                      disabled={isSubmitting}
                      className="justify-center"
                      onClick={() => handleBlockOrUnblock(true)}
                    >
                      {isSubmitting ? "Loading..." : "Block"}
                    </DangerButton>
                  )}
                  {user.kicked && (
                    <div className="flex items-center">
                      <span className="text-red-500">This user blocked</span>
                    </div>
                  )}
                </div>
                {error && (
                  <p className="text-sm text-red-500 dark:text-red-400">
                    {error}
                  </p>
                )}
              </div>
            </>
          )}
          <div className="mt-[10px] flex flex-col">
            <Dialog.Close asChild>
              <button
                type="submit"
                className="linear mt-2 w-full rounded-xl bg-brand-500
                py-[12px] text-base font-medium text-white transition
                duration-200 hover:bg-brand-600 active:bg-brand-700
                disabled:pointer-events-none disabled:opacity-50 dark:bg-brand-400
                dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Done
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button
              type="button"
              className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
              aria-label="Close"
            >
              <MdClose />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
