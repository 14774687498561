import Widget from "components/widget/Widget";
import { MdPointOfSale } from "react-icons/md";
import { roundsAPI } from "services/rounds";
import useSWR from "swr";
import { SaleRound } from "./components/SaleRound";
import { SaleRoundNew } from "./components/SaleRoundNew";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { analyticsAPI } from "services/analytics";

const Marketplace = () => {
  const {
    data: currentRound,
    isLoading: isCurrentLoading,
    mutate: fetchCurrentRound,
  } = useSWR("currentRound", () => roundsAPI.getCurrentRound());
  const {
    data: rounds,
    isLoading,
    mutate: fetchRounds,
  } = useSWR("rounds", () => roundsAPI.getRounds());
  const { data: isClaimAvailable } = useSWR(
    "/api/progress",
    () => analyticsAPI.getIsClaimAvailable(),
    {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      keepPreviousData: true,
      revalidateIfStale: false,
    }
  );

  const handleSuccess = () => {
    fetchRounds();
    fetchCurrentRound();
  };

  return (
    <>
      {currentRound && (
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
          <Widget
            icon={<MdPointOfSale className="h-7 w-7" />}
            title={"Current stage of sale"}
            subtitle={
              isCurrentLoading
                ? "Loading..."
                : (
                    <span className="block truncate">{currentRound?.name}</span>
                  ) ?? <br />
            }
          />
        </div>
      )}
      {isLoading && (
        <div className="flex items-center justify-center py-4">
          <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
        </div>
      )}
      <div className="mt-3 grid h-full grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-3">
        {!isLoading && !isClaimAvailable && (
          <SaleRoundNew onSuccess={handleSuccess} />
        )}
        {!!rounds &&
          rounds.length > 0 &&
          rounds.map((round, i) => (
            <SaleRound
              key={i + round.id}
              round={round}
              onUpdate={handleSuccess}
              disabled={isClaimAvailable}
            />
          ))}
      </div>
    </>
  );
};

export default Marketplace;
