import * as Dialog from "@radix-ui/react-dialog";
import Card from "components/card";
import InputField from "components/fields/InputField";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdAddCircleOutline, MdClose } from "react-icons/md";
import { AxiosError } from "axios";
import { BonusProgramForm } from "./BonusProgramForm";
import { BonusFormScheme } from "./scheme";
import { bonusAPI } from "services/bonus";
import { validateRoundDateRange } from "utils/date";
import useSWR from "swr";

interface SaleRoundNewProps {
  onSuccess?: () => void;
}

export function BonusProgramNew({ onSuccess }: SaleRoundNewProps) {
  const [isEditOpen, setEditOpen] = useState(false);
  const { data: bonusPrograms } = useSWR("bonus-programs", () =>
    bonusAPI.getBonusPrograms()
  );

  const form = useForm<BonusFormScheme>({
    defaultValues: {
      name: "",
      amount: 0,
      percentAmount: "",
      fixedBonusEnabled: false,
      minPurchaseAmount: "",
      minPurchaseAmountEnabled: false,
      start: new Date(),
      end: new Date(),
    },
  });

  const handleSubmit = async (data: BonusFormScheme) => {
    try {
      if (data.end.getTime() <= data.start.getTime()) {
        form.setError("end", {
          type: "custom",
          message: "End date must be greater than start date",
        });
        return;
      }
      if (data.percentAmount === "") return;

      let isOverlap = false;
      form.clearErrors("root");
      validateRoundDateRange(bonusPrograms, data.start, data.end, () => {
        form.setError("start", {
          type: "custom",
          message: null,
        });
        form.setError("end", {
          type: "custom",
          message: null,
        });

        form.setError("root", {
          type: "custom",
          message: "The dates cannot overlap with other bonus programs",
        });
        isOverlap = true;
      });

      if (isOverlap) return;

      await bonusAPI.createBonusProgram({
        name: data.name,
        percentAmount: +data.percentAmount,
        amount: data.amount === "" || isNaN(data.amount) ? 0 : data.amount,
        fixedBonusEnabled: data.fixedBonusEnabled,
        minPurchaseAmount:
          data.minPurchaseAmount === "" || isNaN(+data.minPurchaseAmount)
            ? 1
            : +data.minPurchaseAmount,
        minPurchaseAmountEnabled: data.minPurchaseAmountEnabled,
        description: data.description,
        start: new Date(data.start).toISOString(),
        end: new Date(data.end).toISOString(),
      });

      onSuccess?.();
      handleOpenChange(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setError("root", {
          type: "custom",
          message: error.response?.data.message,
        });
      }
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    setEditOpen(isOpen);

    if (isOpen) return;
    form.reset();
  };

  return (
    <>
      <Card
        extra="cursor-pointer rounded-[15px] min-h-[620px]"
        onClick={() => setEditOpen(true)}
      >
        <div className="flex grow flex-col items-center justify-center gap-6 p-6">
          <MdAddCircleOutline className="h-32 w-32 text-gray-600" />
          <div className="text-2xl font-bold uppercase">Create new bonus</div>
        </div>
      </Card>
      <Dialog.Root open={isEditOpen} onOpenChange={handleOpenChange} modal>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[15px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-[#0b1437] dark:text-white">
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <Dialog.Title className="text-mauve12 mb-5 text-[17px] font-medium">
                Create bonus
              </Dialog.Title>
              <FormProvider {...form}>
                <div className="max-h-[480px] grow overflow-y-auto overflow-x-hidden px-px">
                  <fieldset className="mb-[15px] flex flex-col">
                    <InputField
                      variant="auth"
                      state={form.formState.errors.name && "error"}
                      extra="w-full mb-3"
                      label="Name"
                      placeholder="Stage 1"
                      id="name"
                      type="text"
                      {...form.register("name", {
                        required: "This field is required",
                      })}
                    />
                    {form.formState.errors.name && (
                      <span className="text-sm text-red-500 dark:text-red-400">
                        {form.formState.errors.name.message}
                      </span>
                    )}
                  </fieldset>
                  <BonusProgramForm />
                </div>
              </FormProvider>
              <div className="mt-[25px] flex justify-end">
                <button
                  type="submit"
                  className="linear mt-2 w-full rounded-xl bg-brand-500
                  py-[12px] text-base font-medium text-white transition
                  duration-200 hover:bg-brand-600 active:bg-brand-700
                  dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300
                  dark:active:bg-brand-200"
                  disabled={form.formState.isSubmitting}
                >
                  Save changes
                </button>
              </div>
              {form.formState.errors.root && (
                <span className="flex justify-center pt-1 text-sm text-red-500 dark:text-red-400">
                  {form.formState.errors.root.message}
                </span>
              )}
              <Dialog.Close asChild>
                <button
                  type="button"
                  className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                  aria-label="Close"
                >
                  <MdClose />
                </button>
              </Dialog.Close>
            </form>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
