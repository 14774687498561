import axios, { AxiosInstance } from "axios";
import { API_URL } from "constants/api";
import { IBanner } from "types/banners";
import { getAccessToken } from "utils/auth";

export class BannersAPI {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL + "api/banners",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    this._api.defaults.headers.put["Content-Type"] = "application/json";
  }

  async createBanner(banner: IBanner): Promise<IBanner | null> {
    try {
      const token = getAccessToken();
      const { data } = await this._api.post<IBanner>("", banner, {
        headers: {
          authorization: token,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async getBanners(): Promise<IBanner[] | null> {
    try {
      const token = getAccessToken();
      const { data } = await this._api.get<IBanner[]>("", {
        headers: {
          authorization: token,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async deleteBanner(bannerId: string) {
    try {
      const token = getAccessToken();
      const { data } = await this._api.delete<IBanner[]>(`/${bannerId}`, {
        headers: {
          authorization: token,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async updateBanner(banner: IBanner, bannerID: string) {
    try {
      const token = getAccessToken();

      if (!bannerID) {
        return;
      }

      const { data } = await this._api.patch<IBanner[]>(
        `/${bannerID}`,
        banner,
        {
          headers: {
            authorization: token,
          },
        }
      );

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }
}

export const bannerAPI = new BannersAPI();
