import Card from "components/card";
import { useEffect, useMemo, useState } from "react";
import {
  MdArrowBackIosNew,
  MdArrowForwardIos,
  MdFirstPage,
  MdLastPage,
} from "react-icons/md";

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { Button } from "components/Button";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { ordersAPI } from "services/orders";
import useSWR from "swr";
import { useDebounceValue } from "usehooks-ts";
import { columns } from "./complexTableColumns";
import { ButtonSM } from "components/Button/Small";

export default function ComplexTable() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [searchDebounce] = useDebounceValue(search, 1000);
  const [stopAutoRefresh, setStopAutoRefresh] = useState(false);

  const sortBy = useMemo(() => {
    return sorting
      .map((s) => `${s.id}:${s.desc ? "DESC" : "ASC"}`)
      .join("&sortBy=");
  }, [sorting]);

  const {
    data: tableData,
    isLoading,
    mutate,
  } = useSWR(
    `/orders?page=${page}&search=${searchDebounce}&sortBy=${sortBy}`,
    () =>
      ordersAPI.getOrders(page, 20, searchDebounce.trim(), '', sortBy),
    {
      refreshInterval: stopAutoRefresh ? null : 5000,
      revalidateOnFocus: !stopAutoRefresh,
    }
  );

  useEffect(() => {
    handlePageChange(1);
  }, [searchDebounce]);

  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > (tableData?.meta?.totalPages ?? 1)) return;

    setPage(newPage);
  };

  const [data] = useDebounceValue(tableData?.data ?? [], 100);

  const table = useReactTable({
    data: data,
    columns,
    state: {
      sorting,
      globalFilter: search,
    },
    onGlobalFilterChange: setSearch,
    onSortingChange: setSorting,
    onColumnFiltersChange: (col) => {
      if (typeof col === "function") {
        const filters = col([]);

        const editUserFilter = filters.find((el) => el.id === "editUser");

        if (editUserFilter) {
          setStopAutoRefresh(!!editUserFilter.value);

          if (!editUserFilter.value) {
            mutate();
          }
        }
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Card
      extra={
        "w-full h-[800px]  md:h-[500px] px-6 pb-6 sm:overflow-x-auto rounded-[15px]"
      }
    >
      <div className="relative flex flex-col items-center justify-between gap-2 pt-4 sm:flex-row sm:gap-2">
        <div className="flex w-full flex-col gap-2 sm:w-auto sm:flex-row sm:items-center">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Purchase history
          </div>
          <ButtonSM onClick={() => ordersAPI.loadCSV()}>Load CSV file</ButtonSM>
          <ButtonSM onClick={() => ordersAPI.loadXLS()}>
            Load Excel (.xlsx)
          </ButtonSM>
        </div>
        <input
          type="text"
          placeholder="Search by address or email"
          className="block w-full grow rounded-full bg-lightPrimary px-4 text-base font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:h-full sm:w-fit sm:max-w-96"
          value={search}
          onChange={(e) => {
            mutate();
            setSearch(e.target.value);
          }}
        />
      </div>

      <div className="mt-8 flex grow flex-col overflow-x-auto">
        <table className="w-full">
          <thead className="sticky top-0 z-10">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className={clsx(
                        "bg-white pb-2 pr-4 pt-4 text-start first:rounded-l-lg last:rounded-r-lg dark:bg-navy-800",
                        header.column.getCanSort() && "cursor-pointer"
                      )}
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td className="absolute left-1/2 -translate-x-1/2" colSpan={8}>
                  <p className="flex justify-center py-10">
                    <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
                  </p>
                </td>
              </tr>
            )}
            {!!tableData?.data && !isLoading && tableData.data.length <= 0 && (
              <tr>
                <td className="absolute left-1/2 -translate-x-1/2" colSpan={8}>
                  <p className="py-10 text-center font-bold uppercase">
                    No data
                  </p>
                </td>
              </tr>
            )}
            {!isLoading &&
              !!tableData?.data &&
              tableData.data.length > 0 &&
              table.getRowModel().rows.map((row) => {
                return (
                  <tr
                    key={row.id}
                    className="[&_td]:odd:bg-gray-50 [&_td]:odd:dark:bg-navy-900"
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="border-white/0 py-3 pr-4 first:rounded-l-lg last:rounded-r-lg"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="mt-auto flex flex-col justify-start gap-2 pt-5 md:flex-row md:items-center">
        <Button disabled={page <= 1} onClick={() => handlePageChange(1)}>
          <MdFirstPage /> First
        </Button>
        <Button disabled={page <= 1} onClick={() => handlePageChange(page - 1)}>
          <MdArrowBackIosNew /> Previous
        </Button>
        <Button
          disabled={page >= (tableData?.meta?.totalPages ?? 1)}
          onClick={() => handlePageChange(page + 1)}
        >
          Next
          <MdArrowForwardIos />
        </Button>
        <Button
          disabled={page >= (tableData?.meta?.totalPages ?? 1)}
          onClick={() => handlePageChange(tableData?.meta?.totalPages ?? 1)}
        >
          Last
          <MdLastPage />
        </Button>
        <div className="flex flex-col items-center gap-4 text-gray-700 dark:text-gray-200 md:ml-auto md:flex-row">
          <div>Current page: {tableData?.meta?.currentPage ?? 1}</div>
          <div>Total items: {tableData?.meta?.totalItems ?? 0}</div>
          <div>Total pages: {tableData?.meta?.totalPages ?? 1}</div>
        </div>
      </div>
    </Card>
  );
}
