import { Controller, useFormContext } from "react-hook-form";
import InputField from "components/fields/InputField";
import {
  formatDateFromString,
  formatTimeFromString,
  toDateTimeLocalString,
} from "utils/date";
import { BonusFormScheme } from "./scheme";
import TextArea from "components/fields/TextArea";
import Switch from "components/switch";

export function BonusProgramForm() {
  const form = useFormContext<BonusFormScheme>();

  if (!form) {
    throw new Error("useFormContext must be used inside a FormProvider");
  }

  const minPurchaseAmountEnabled = form.watch("minPurchaseAmountEnabled");

  return (
    <>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.percentAmount && "error"}
          extra="w-full mb-3"
          label="Bonus amount (%)"
          placeholder="5"
          id="percentAmount"
          type="number"
          {...form.register("percentAmount", {
            required: "This field is required",
            pattern: {
              value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
              message: "Invalid number format",
            },
            min: {
              value: 1,
              message: "Bonus amount should be anywhere between 1% to 100%",
            },
            max: {
              value: 100,
              message: "Bonus amount should be anywhere between 1% to 100%",
            },
          })}
        />
        {form.formState.errors.percentAmount && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.percentAmount.message}
          </span>
        )}
      </fieldset>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.minPurchaseAmount && "error"}
          extra="w-full mb-3"
          label="Minimum purchase to get bonus"
          placeholder="100 USD"
          id="minPurchase"
          type="number"
          disabled={!minPurchaseAmountEnabled}
          min={0}
          step={0.01}
          {...form.register("minPurchaseAmount", {
            disabled: !minPurchaseAmountEnabled,
            required: minPurchaseAmountEnabled
              ? "This field is required"
              : false,
            pattern: {
              value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
              message: "Invalid number format",
            },
            min: {
              value: 0,
              message: "The value must be greater than 0",
            },
          })}
        />
        {form.formState.errors.minPurchaseAmount &&
          minPurchaseAmountEnabled && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.minPurchaseAmount.message}
            </span>
          )}
      </fieldset>

      <fieldset className="mb-[15px] ml-1.5 flex">
        <label
          htmlFor="doubleReferralBonus"
          className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
        >
          Minimum purchase enabled
        </label>
        <Switch
          id="minPurchaseAmountEnabled"
          {...form.register("minPurchaseAmountEnabled")}
        />
      </fieldset>
      <div className="flex flex-col">
        <div className="mb-[15px] grid grid-cols-2 gap-x-4">
          <fieldset className="flex flex-col">
            <Controller
              name="start"
              control={form.control}
              render={({ field }) => (
                <>
                  <InputField
                    variant="auth"
                    state={form.formState.errors.start && "error"}
                    extra="w-full mb-3"
                    label="Start date"
                    placeholder="10000"
                    id="startAt"
                    type="datetime-local"
                    value={toDateTimeLocalString(field.value)}
                    onChange={(e) => e.target.value && field.onChange(new Date(e.target.value))}
                    onBlur={field.onBlur}
                    disabled={field.disabled}
                    ref={field.ref}
                    name={field.name}
                  />
                  <span className="-mt-1 text-sm text-gray-600">
                    UTC: {formatDateFromString(field.value.toString(), true)}{" "}
                    {formatTimeFromString(field.value.toString(), true)}
                  </span>
                </>
              )}
            />
            {form.formState.errors.start && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.start.message}
              </span>
            )}
          </fieldset>
          <fieldset className="flex flex-col">
            <Controller
              name="end"
              control={form.control}
              render={({ field }) => (
                <>
                  <InputField
                    variant="auth"
                    state={form.formState.errors.end && "error"}
                    extra="w-full mb-3"
                    label="End date"
                    placeholder="10000"
                    id="end"
                    type="datetime-local"
                    value={toDateTimeLocalString(field.value)}
                    onChange={(e) => e.target.value && field.onChange(new Date(e.target.value))}
                    onBlur={field.onBlur}
                    disabled={field.disabled}
                    ref={field.ref}
                    name={field.name}
                  />
                  <span className="-mt-1 text-sm text-gray-600">
                    UTC: {formatDateFromString(field.value.toString(), true)}{" "}
                    {formatTimeFromString(field.value.toString(), true)}
                  </span>
                </>
              )}
            />
            {form.formState.errors.end && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.end.message}
              </span>
            )}
          </fieldset>
        </div>
        <fieldset className="flex flex-col">
          <TextArea
            variant="auth"
            state={form.formState.errors.description && "error"}
            extra="w-full mb-3"
            label="Description"
            placeholder="Bonus 5% for every 100 USD purchase"
            id="description"
            {...form.register("description", {
              required: "This field is required",
            })}
          />
          {form.formState.errors.description && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.description.message}
            </span>
          )}
        </fieldset>
      </div>
    </>
  );
}
