export interface AnalyticsResponse {
  totalSold: number | null;
  totalUnsold: number | null;
  totalMoney: number | null;
  sold24h: number | null;
  moneyIncoming24h: number | null;
  totalUsers: number;
  users24h: number;
}

export enum ChartGroupBy {
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
}

export enum ChartType {
  DEFAULT = "",
  USD = "usd",
  Tokens = "tokens",
}

export interface User {
  id: string;
  wallet: string;
  email: string;
  createdAt: string;
  referralCode: {
    id: string;
    code: string;
  };
  bonusForInvitedPercent: number;
  totalWorth: number;
  totalWorthUsd: number;
  referrerEmail: number;
  totalBonuses: number;
  kicked: boolean;
}

export interface Settings {
  id: string;
  updatedAt: string;
  emailRequiredEnabled: boolean;
  nowPaymentsLimitMessageEnabled: boolean;
}
