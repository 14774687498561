import axios, { AxiosInstance } from "axios";
import { API_URL } from "constants/api";
import { BonusProgram } from "types/bonusProgram";
import { ReferralCreateRequest, ReferralProgram } from "types/referralProgram";
import { getAccessToken } from "utils/auth";

export class ReferralAPI {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL + "api/referral-bonuses",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    this._api.defaults.headers.put["Content-Type"] = "application/json";
  }

  async getReferralPrograms() {
    try {
      const token = getAccessToken();

      const { data } = await this._api.get<ReferralProgram[]>("", {
        headers: {
          authorization: token,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return [];
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async createReferralProgram(requestBody: ReferralCreateRequest) {
    const { data } = await this._api.post<ReferralProgram>(
      "",
      {
        ...requestBody,
      },
      {
        headers: {
          authorization: getAccessToken(),
        },
      }
    );

    return data;
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async updateReferralProgram(
    referralProgramId: BonusProgram["id"],
    requestBody: Omit<ReferralCreateRequest, "name">
  ) {
    const { data } = await this._api.patch<ReferralProgram>(
      `/${referralProgramId}`,
      requestBody,
      {
        headers: {
          authorization: getAccessToken(),
        },
      }
    );

    return data;
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async deleteReferralProgram(referralProgramId: ReferralProgram["id"]) {
    const { data } = await this._api.delete<ReferralProgram>(
      `/${referralProgramId}`,
      {
        headers: {
          authorization: getAccessToken(),
        },
      }
    );

    return data;
  }
}

export const referralAPI = new ReferralAPI();
