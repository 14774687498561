import axios, { AxiosInstance } from "axios";
import { API_URL } from "constants/api";
import { BonusCreateRequest, BonusProgram } from "types/bonusProgram";
import { getAccessToken } from "utils/auth";

export class BonusAPI {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL + "api/bonuses",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    this._api.defaults.headers.put["Content-Type"] = "application/json";
  }

  async getBonusPrograms() {
    try {
      const token = getAccessToken();

      const { data } = await this._api.get<BonusProgram[]>("", {
        headers: {
          authorization: token,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return [];
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async createBonusProgram(requestBody: BonusCreateRequest) {
    const { data } = await this._api.post<BonusProgram>(
      "",
      {
        ...requestBody,
      },
      {
        headers: {
          authorization: getAccessToken(),
        },
      }
    );

    return data;
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async updateBonusProgram(
    bonusId: BonusProgram["id"],
    requestBody: Omit<BonusCreateRequest, "name">
  ) {
    const { data } = await this._api.patch<BonusProgram>(
      `/${bonusId}`,
      requestBody,
      {
        headers: {
          authorization: getAccessToken(),
        },
      }
    );

    return data;
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async deleteRound(bonusId: BonusProgram["id"]) {
    const { data } = await this._api.delete<BonusProgram>(`/${bonusId}`, {
      headers: {
        authorization: getAccessToken(),
      },
    });

    return data;
  }
}

export const bonusAPI = new BonusAPI();
