import { Controller, useFormContext } from "react-hook-form";
import { RoundFormScheme } from "./scheme";
import InputField from "components/fields/InputField";
import {
  formatDateFromString,
  formatTimeFromString,
  toDateTimeLocalString,
} from "utils/date";

export function SaleRoundForm() {
  const form = useFormContext<RoundFormScheme>();

  if (!form) {
    throw new Error("useFormContext must be used inside a FormProvider");
  }

  const startDate = form.watch("startDate");
  const endDate = form.watch("endDate");

  return (
    <>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.name && "error"}
          extra="w-full mb-3"
          label="Name"
          placeholder="Stage 1"
          id="name"
          type="text"
          {...form.register("name", {
            required: "This field is required",
          })}
        />
        {form.formState.errors.name && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.name.message}
          </span>
        )}
      </fieldset>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.tokenPrice && "error"}
          extra="w-full mb-3"
          label="Token price"
          placeholder="1.5 USD"
          id="tokenPrice"
          type="number"
          min={0.0000001}
          step={0.0000001}
          {...form.register("tokenPrice", {
            required: "This field is required",
            pattern: {
              value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
              message: "Invalid number format",
            },
            min: {
              value: 0.0000001,
              message: "The value must be greater than 0",
            },
          })}
        />
        {form.formState.errors.tokenPrice && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.tokenPrice.message}
          </span>
        )}
      </fieldset>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.amountOfTokens && "error"}
          extra="w-full mb-3"
          label="Amount of tokens to sale"
          placeholder="10000"
          id="amountOfTokens"
          type="number"
          min={0}
          step={0.01}
          {...form.register("amountOfTokens", {
            required: "This field is required",
            pattern: {
              value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
              message: "Invalid number format",
            },
            min: {
              value: 0,
              message: "The value must be greater than 0",
            },
          })}
        />
        {form.formState.errors.amountOfTokens && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.amountOfTokens.message}
          </span>
        )}
      </fieldset>
      <div className="flex flex-col">
        <div className="grid grid-cols-2 gap-x-4">
          <fieldset className="flex flex-col">
            <Controller
              name="startDate"
              control={form.control}
              render={({ field }) => (
                <>
                  <InputField
                    variant="auth"
                    state={form.formState.errors.startDate && "error"}
                    extra="w-full mb-3"
                    label="Start date"
                    placeholder="10000"
                    id="startAt"
                    type="datetime-local"
                    value={toDateTimeLocalString(field.value)}
                    onChange={(e) => e.target.value && field.onChange(new Date(e.target.value))}
                    onBlur={field.onBlur}
                    disabled={field.disabled}
                    ref={field.ref}
                    name={field.name}
                    max={toDateTimeLocalString(endDate)}
                  />
                  <span className="-mt-1 text-sm text-gray-600">
                    UTC: {formatDateFromString(field.value.toString(), true)}{" "}
                    {formatTimeFromString(field.value.toString(), true)}
                  </span>
                </>
              )}
            />
            {form.formState.errors.startDate && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.startDate.message}
              </span>
            )}
          </fieldset>
          <fieldset className="flex flex-col">
            <Controller
              name="endDate"
              control={form.control}
              render={({ field }) => (
                <>
                  <InputField
                    variant="auth"
                    state={form.formState.errors.endDate && "error"}
                    extra="w-full mb-3"
                    label="End date"
                    placeholder="10000"
                    id="endDate"
                    type="datetime-local"
                    value={toDateTimeLocalString(field.value)}
                    onChange={(e) => e.target.value && field.onChange(new Date(e.target.value))}
                    onBlur={field.onBlur}
                    disabled={field.disabled}
                    ref={field.ref}
                    name={field.name}
                    min={toDateTimeLocalString(startDate)}
                  />
                  <span className="-mt-1 text-sm text-gray-600">
                    UTC: {formatDateFromString(field.value.toString(), true)}{" "}
                    {formatTimeFromString(field.value.toString(), true)}
                  </span>
                </>
              )}
            />
            {form.formState.errors.endDate && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.endDate.message}
              </span>
            )}
          </fieldset>
        </div>
        {form.formState.errors.root && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.root.message}
          </span>
        )}
      </div>
    </>
  );
}
