import { Controller, useFormContext } from "react-hook-form";
import { RoundFormScheme } from "./scheme";
import InputField from "components/fields/InputField";
import { formatDateFromString, formatTimeFromString, toDateTimeLocalString } from "utils/date";
import Switch from "components/switch";
import TextArea from "components/fields/TextArea";

export function ReferralProgramForm() {
  const form = useFormContext<RoundFormScheme>();

  if (!form) {
    throw new Error("useFormContext must be used inside a FormProvider");
  }

  const startDate = form.watch("start");
  const endDate = form.watch("end");
  const minAmountToGetCodeEnabled = form.watch("minAmountToGetCodeEnabled");
  const minAmountToGetBonusEnabled = form.watch("minAmountToGetBonusEnabled");

  return (
    <>
      <fieldset className="mb-[15px] ml-1.5 flex">
        <label
          htmlFor="doubleReferralBonus"
          className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
        >
          Referral program enabled
        </label>
        <Switch label="" id="enabled" {...form.register("enabled")} />
      </fieldset>
      <fieldset className="mb-[15px] ml-1.5 flex">
        <label
          htmlFor="doubleReferralBonus"
          className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
        >
          Double referral bonus
        </label>
        <Switch
          label="Double referral bonus"
          id="doubleReferralBonus"
          {...form.register("doubleBonusEnabled")}
        />
      </fieldset>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.refereeBonusPercentAmount && "error"}
          extra="w-full mb-3"
          label="% of bonus for referee"
          placeholder="1 %"
          id="refereeAmount"
          type="number"
          min={0.1}
          step={0.01}
          max={100}
          {...form.register("refereeBonusPercentAmount", {
            required: "This field is required",
            pattern: {
              value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
              message: "Invalid number format",
            },
            min: {
              value: 0.1,
              message: "The value must be greater than 0.1%",
            },
            max: {
              value: 100,
              message: "The value must be less than 100%",
            },
          })}
        />
        {form.formState.errors.refereeBonusPercentAmount && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.refereeBonusPercentAmount.message}
          </span>
        )}
      </fieldset>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.referrerBonusPercentAmount && "error"}
          extra="w-full mb-3"
          label="% of bonus for referral"
          placeholder="1 %"
          id="referralAmount"
          type="number"
          min={0}
          step={0.01}
          {...form.register("referrerBonusPercentAmount", {
            required: "This field is required",
            pattern: {
              value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
              message: "Invalid number format",
            },
            min: {
              value: 0,
              message: "The value must be greater than 0.1%",
            },
            max: {
              value: 100,
              message: "The value must be less than 100%",
            },
          })}
        />
        {form.formState.errors.referrerBonusPercentAmount && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.referrerBonusPercentAmount.message}
          </span>
        )}
      </fieldset>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.minAmountToGetCode && "error"}
          extra="w-full mb-3"
          label="Min amount to get referral code"
          placeholder="100 USD"
          id="minToGetCode"
          type="number"
          min={0}
          step={0.0000001}
          disabled={!minAmountToGetCodeEnabled}
          {...form.register("minAmountToGetCode", {
            disabled: !minAmountToGetCodeEnabled,
            required: minAmountToGetCodeEnabled
              ? "This field is required"
              : undefined,
            pattern: {
              value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
              message: "Invalid number format",
            },
            min: {
              value: 0,
              message: "The value must be greater than 0",
            },
          })}
        />
        {form.formState.errors.minAmountToGetCode &&
          minAmountToGetCodeEnabled && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.minAmountToGetCode.message}
            </span>
          )}
      </fieldset>
      <fieldset className="mb-[15px] ml-1.5 flex">
        <label
          htmlFor="doubleReferralBonus"
          className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
        >
          Enable min limit to get referral code
        </label>
        <Switch
          label=""
          id="minAmountToGetCodeEnabled"
          {...form.register("minAmountToGetCodeEnabled")}
        />
      </fieldset>
      <fieldset className="mb-[15px] flex flex-col">
        <InputField
          variant="auth"
          state={form.formState.errors.minAmountToGetBonus && "error"}
          extra="w-full mb-3"
          label="Min amount to get referral bonus"
          placeholder="100 USD"
          id="minToGetBonus"
          disabled={!minAmountToGetBonusEnabled}
          type="number"
          min={0}
          step={0.0000001}
          {...form.register("minAmountToGetBonus", {
            disabled: !minAmountToGetBonusEnabled,
            required: minAmountToGetBonusEnabled
              ? "This field is required"
              : undefined,
            pattern: {
              value: /^[0-9]+([.,]{1}[0-9]+)?$/g,
              message: "Invalid number format",
            },
            min: {
              value: 0,
              message: "The value must be greater than 0",
            },
          })}
        />
        {form.formState.errors.minAmountToGetBonus &&
          minAmountToGetBonusEnabled &&
          minAmountToGetBonusEnabled && (
            <span className="text-sm text-red-500 dark:text-red-400">
              {form.formState.errors.minAmountToGetBonus.message}
            </span>
          )}
      </fieldset>
      <fieldset className="mb-[15px] ml-1.5 flex">
        <label
          htmlFor="minAmountToGetBonusEnabled"
          className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
        >
          Enable min limit to get referral bonus
        </label>
        <Switch
          label=""
          id="minAmountToGetBonusEnabled"
          {...form.register("minAmountToGetBonusEnabled")}
        />
      </fieldset>
      <div className="flex flex-col">
        <div className="mb-[15px] grid grid-cols-2 gap-x-4">
          <fieldset className="flex flex-col">
            <Controller
              name="start"
              control={form.control}
              render={({ field }) => (
                <>
                  <InputField
                    variant="auth"
                    state={form.formState.errors.start && "error"}
                    extra="w-full mb-3"
                    label="Start date"
                    placeholder="10000"
                    id="startAt"
                    type="datetime-local"
                    value={toDateTimeLocalString(field.value)}
                    onChange={(e) => e.target.value && field.onChange(new Date(e.target.value))}
                    onBlur={field.onBlur}
                    disabled={field.disabled}
                    ref={field.ref}
                    name={field.name}
                    max={toDateTimeLocalString(endDate)}
                  />
                  <span className="-mt-1 text-sm text-gray-600">
                    UTC: {formatDateFromString(field.value.toString(), true)}{" "}
                    {formatTimeFromString(field.value.toString(), true)}
                  </span>
                </>
              )}
            />
            {form.formState.errors.start && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.start.message}
              </span>
            )}
          </fieldset>
          <fieldset className="flex flex-col">
            <Controller
              name="end"
              control={form.control}
              render={({ field }) => (
                <>
                  <InputField
                    variant="auth"
                    state={form.formState.errors.end && "error"}
                    extra="w-full mb-3"
                    label="End date"
                    placeholder="10000"
                    id="endDate"
                    type="datetime-local"
                    value={toDateTimeLocalString(field.value)}
                    onChange={(e) => e.target.value && field.onChange(new Date(e.target.value))}
                    onBlur={field.onBlur}
                    disabled={field.disabled}
                    ref={field.ref}
                    name={field.name}
                    min={toDateTimeLocalString(startDate)}
                  />
                  <span className="-mt-1 text-sm text-gray-600">
                    UTC: {formatDateFromString(field.value.toString(), true)}{" "}
                    {formatTimeFromString(field.value.toString(), true)}
                  </span>
                </>
              )}
            />
            {form.formState.errors.end && (
              <span className="text-sm text-red-500 dark:text-red-400">
                {form.formState.errors.end.message}
              </span>
            )}
          </fieldset>
        </div>
        {form.formState.errors.root && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.root.message}
          </span>
        )}
      </div>

      <fieldset className="mb-[15px] flex flex-col">
        <TextArea
          variant="auth"
          state={form.formState.errors.description && "error"}
          extra="w-full mb-3"
          label="Description"
          placeholder="Referral bonus 1%"
          id="description"
          {...form.register("description", {
            required: "This field is required",
          })}
        />
        {form.formState.errors.description && (
          <span className="text-sm text-red-500 dark:text-red-400">
            {form.formState.errors.description.message}
          </span>
        )}
      </fieldset>
    </>
  );
}
