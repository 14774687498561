import * as Dialog from "@radix-ui/react-dialog";
import { AxiosError } from "axios";
import Card from "components/card";
import PieChart from "components/charts/PieChart";
import Dropdown from "components/dropdown";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BsThreeDots } from "react-icons/bs";
import { MdClose, MdDelete, MdEdit } from "react-icons/md";
import { roundsAPI } from "services/rounds";
import useSWR from "swr";
import { Round } from "types/rounds";
import {
  formatDateFromString,
  formatTimeFromString,
  validateRoundDateRange,
} from "utils/date";
import { formatNumber, toFixed } from "utils/number";
import { pieChartOptions } from "variables/charts";
import { Badge } from "./Badge";
import { SaleRoundForm } from "./SaleRoundForm";
import { RoundFormScheme } from "./scheme";

export function SaleRound({
  round,
  onUpdate,
  disabled = false,
}: {
  round: Round;
  onUpdate?: () => void;
  disabled?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const { data: rounds } = useSWR("rounds", () => roundsAPI.getRounds());

  const [startAtDate, startAtTime, startAtDateUTC, startAtTimeUTC] =
    useMemo(() => {
      const data = formatDateFromString(round.startAt);
      const dateUTC = formatDateFromString(round.startAt, true);
      const time = formatTimeFromString(round.startAt);
      const timeUTC = formatTimeFromString(round.startAt, true);
      return [data, time, dateUTC, timeUTC];
    }, [round.startAt]);

  const [finishAtDate, finishAtTime, finishAtDateUTC, finishAtTimeUTC] =
    useMemo(() => {
      const data = formatDateFromString(round.finishAt);
      const dateUTC = formatDateFromString(round.finishAt, true);
      const time = formatTimeFromString(round.finishAt);
      const timeUTC = formatTimeFromString(round.finishAt, true);
      return [data, time, dateUTC, timeUTC];
    }, [round.finishAt]);

  const form = useForm<RoundFormScheme>({
    values: {
      name: round.name,
      tokenPrice: toFixed(round.tokenPrice, 7),
      amountOfTokens: round.pool.toString(),
      startDate: new Date(round.startAt),
      endDate: new Date(round.finishAt),
    },
  });

  const handleSubmit = async (data: RoundFormScheme) => {
    try {
      if (data.endDate.getTime() <= data.startDate.getTime()) {
        form.setError("endDate", {
          type: "custom",
          message: "End date must be greater than start date",
        });
        return;
      }
      if (data.tokenPrice === "" || data.amountOfTokens === "") return;

      let isOverlap = false;
      form.clearErrors("root");

      validateRoundDateRange(
        rounds.filter((r) => r.id !== round.id),
        data.startDate,
        data.endDate,
        () => {
          form.setError("startDate", {
            type: "custom",
            message: null,
          });
          form.setError("endDate", {
            type: "custom",
            message: null,
          });

          form.setError("root", {
            type: "custom",
            message: "The dates cannot overlap with other stages",
          });
          isOverlap = true;
        }
      );

      if (isOverlap) return;

      await roundsAPI.updateRound(round.id, {
        name: data.name,
        tokenPrice: +data.tokenPrice,
        pool: +data.amountOfTokens,
        startAt: data.startDate.toISOString(),
        finishAt: data.endDate.toISOString(),
      });

      onUpdate?.();
      handleOpenChange(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setError("root", {
          type: "custom",
          message: error.response?.data.message,
        });
      }
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    setEditOpen(isOpen);

    if (isOpen) return;
    form.reset();
  };

  const [badgeName, badgeStyle] = useMemo(() => {
    const now = Date.now();
    const startAt = new Date(round.startAt).getTime();
    const finishAt = new Date(round.finishAt).getTime();
    if (now < startAt) {
      return ["Upcoming", "bg-yellow-100 text-yellow-500"];
    }
    if (now > startAt && now < finishAt) {
      return [
        "Running",
        "bg-green-500 text-white dark:bg-green-300 dark:text-green-800",
      ];
    }
    if (now > finishAt) {
      return ["Finished", "bg-red-100 text-red-500"];
    }
  }, [round.startAt, round.finishAt]);

  const handleDelete = () => {
    if (window.confirm("Are you sure to delete this stage?")) {
      roundsAPI.deleteRound(round.id).then(() => {
        onUpdate?.();
      });
    }
  };

  return (
    <>
      <Card extra="rounded-[15px] min-h-[620px]">
        <div className="p-6">
          <div className="mb-6 flex max-w-full justify-between">
            <div className="flex max-w-[calc(100%-40px)] flex-col gap-2">
              <div className="flex items-center gap-2 text-sm font-bold uppercase tracking-wider text-gray-500">
                Stage name
                <Badge extra={badgeStyle}>{badgeName}</Badge>
              </div>
              <h2 className="line-clamp-2 break-words text-xl">{round.name}</h2>
            </div>
            <div>
              {!disabled && (
                <Dropdown
                  button={
                    <button
                      onClick={() => setOpen(!open)}
                      className={`linear flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-xl font-bold text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10`}
                    >
                      <BsThreeDots className="h-6 w-6" />
                    </button>
                  }
                  animation={
                    "origin-top-right transition-all duration-300 ease-in-out"
                  }
                  classNames={`top-11 right-0 w-max`}
                  children={
                    <div className="z-50 flex w-max flex-col gap-2 rounded-xl bg-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                      <button
                        onClick={() => setEditOpen(true)}
                        className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600"
                      >
                        <span>
                          <MdEdit />
                        </span>
                        Edit stage
                      </button>
                      <button
                        onClick={handleDelete}
                        className="flex cursor-pointer items-center gap-2 text-red-600 hover:text-red-500"
                      >
                        <span>
                          <MdDelete />
                        </span>
                        Delete stage
                      </button>
                    </div>
                  }
                />
              )}
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex max-h-40 max-w-32 items-center justify-center">
              <PieChart
                chartOptions={pieChartOptions}
                chartData={[round.sold, round.unsold]}
              />
            </div>
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <div className="h-4 w-4 rounded-sm bg-[#adb5bd]" />
                <b>Unsold</b> - {formatNumber(round.unsold)}
              </div>
              <div className="flex items-center gap-2">
                <div className="h-4 w-4 rounded-sm bg-[#4318FF]" />
                <b>Sold</b> - {formatNumber(round.sold)}
              </div>
              <div className="pl-6">
                <b>Total</b> - {formatNumber(round.pool)}
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t p-6">
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Base price</span>
            <span className="truncate text-lg font-medium">
              {toFixed(round.tokenPrice, 7)}{" "}
              <span className="text-sm font-normal text-gray-500">USD</span>
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Max. per wallet</span>
            <span className="text-lg font-medium">
              {formatNumber(round.maxPurchasePerWallet)}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t p-6">
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">Start date</span>
            <span className="text-lg font-medium">{startAtDate}</span>
            <span className="text-sm">{startAtTime}</span>
          </div>
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">End date</span>
            <span className="text-lg font-medium">{finishAtDate}</span>
            <span className="text-sm">{finishAtTime}</span>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t p-6">
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">Start date (UTC)</span>
            <span className="text-lg font-medium">{startAtDateUTC}</span>
            <span className="text-sm">{startAtTimeUTC}</span>
          </div>
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">End date (UTC)</span>
            <span className="text-lg font-medium">{finishAtDateUTC}</span>
            <span className="text-sm">{finishAtTimeUTC}</span>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t p-6">
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">Total sold</span>
            <span className="text-lg font-medium">
              {formatNumber(round.totalSold)}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">Total remaining</span>
            <span className="text-lg font-medium">
              {formatNumber(round.totalUnsold)}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t p-6">
          <div
            className="flex flex-col"
            title={formatNumber(round.soldUsd) + " USD"}
          >
            <span className="mb-1 text-gray-500">Sold by round</span>
            <span className="truncate text-lg font-medium">
              {formatNumber(round.soldUsd)}{" "}
              <span className="text-sm font-normal text-gray-500">USD</span>
            </span>
          </div>
          <div
            className="flex flex-col"
            title={formatNumber(round.totalSoldUsd) + " USD"}
          >
            <span className="mb-1 text-gray-500">Total sold</span>
            <span className="truncate text-lg font-medium">
              {formatNumber(round.totalSoldUsd)}{" "}
              <span className="text-sm font-normal text-gray-500">USD</span>
            </span>
          </div>
        </div>
      </Card>
      <Dialog.Root open={isEditOpen} onOpenChange={handleOpenChange} modal>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-gray-800 dark:text-white">
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <Dialog.Title className="text-mauve12 mb-10 text-[17px] font-medium">
                Edit stage
              </Dialog.Title>
              <FormProvider {...form}>
                <SaleRoundForm />
              </FormProvider>
              <div className="mt-[10px] flex flex-col">
                <button
                  type="submit"
                  className="linear mt-2 w-full rounded-xl bg-brand-500
                  py-[12px] text-base font-medium text-white transition
                  duration-200 hover:bg-brand-600 active:bg-brand-700
                  disabled:pointer-events-none disabled:opacity-50 dark:bg-brand-400
                  dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled={
                    form.formState.isSubmitting || !form.formState.isDirty
                  }
                >
                  {form.formState.isSubmitting ? "Saving..." : "Save changes"}
                </button>
              </div>
            </form>
            <Dialog.Close asChild>
              <button
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
