import {
  MdBarChart,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdGroup,
  MdLink,
  MdMonetizationOn,
  MdOpenInNew,
  MdPerson,
} from "react-icons/md";

import Widget from "components/widget/Widget";
import ComplexTable from "views/admin/default/components/ComplexTable";
import useSWR from "swr";
import { analyticsAPI } from "services/analytics";
import { SalesChart } from "./components/SalesChart";
import { UsersChart } from "./components/UsersChart";
import { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { TOKEN_NAME } from "constants/token";

const Dashboard = () => {
  const { data, isLoading } = useSWR("/api/analytics", () =>
    analyticsAPI.getAnalytics()
  );
  const { data: settings, mutate, isLoading: isSettingsLoading } = useSWR("/api/settings", () =>
    analyticsAPI.getSettings()
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingEmailRequired, setIsLoadingEmailRequired] = useState(false);

  const handleNowPaymentsLimitMessage = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    await analyticsAPI.updateSettings({
      nowPaymentsLimitMessageEnabled: !settings?.nowPaymentsLimitMessageEnabled,
    });
    await mutate();
    setIsSubmitting(false);
  };

  const handleEmailRequired = async () => {
    if (isLoadingEmailRequired) return;
    setIsLoadingEmailRequired(true);
    await analyticsAPI.updateSettings({
      emailRequiredEnabled: !settings?.emailRequiredEnabled,
    });
    await mutate();
    setIsLoadingEmailRequired(false);
  };

  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Amount of sold tokens (Total)"}
          subtitle={
            isLoading
              ? "Loading..."
              : `${data?.totalSold?.toFixed(2) ?? 0} ${TOKEN_NAME}`
          }
        />
        <Widget
          icon={<MdBarChart className="h-6 w-6" />}
          title={"Amount of unsold tokens (Total)"}
          subtitle={
            isLoading
              ? "Loading..."
              : `${data?.totalUnsold?.toFixed(2) ?? 0} ${TOKEN_NAME}`
          }
        />
        <Widget
          icon={<MdMonetizationOn className="h-7 w-7" />}
          title={"Amount Raised $ (Total)"}
          subtitle={
            isLoading ? "Loading..." : `$${data?.totalMoney?.toFixed(2) ?? 0}`
          }
        />
        <Widget
          icon={<MdGroup className="h-6 w-6" />}
          title={"Total users"}
          subtitle={isLoading ? "Loading..." : data?.totalUsers ?? 0}
        />
        <Widget
          icon={<MdPerson className="h-7 w-7" />}
          title={"New users (24 hours)"}
          subtitle={isLoading ? "Loading..." : data?.users24h ?? 0}
        />
        <a
          href="https://nowpayments.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Widget
            icon={<MdLink className="h-7 w-7" />}
            title={""}
            subtitle={
              <div className="flex items-center gap-2">
                Payment provider <MdOpenInNew />
              </div>
            }
          />
        </a>
      </div>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Token purchases (last 24 hours)"}
          subtitle={
            isLoading ? "Loading..." : `${data?.sold24h ?? 0} ${TOKEN_NAME}`
          }
        />
        <Widget
          icon={<MdBarChart className="h-6 w-6" />}
          title={"Money incoming (last 24 hours)"}
          subtitle={
            isLoading ? "Loading..." : `$${data?.moneyIncoming24h ?? 0}`
          }
        />
        <button
          className="rounded-3xl text-left transition-opacity duration-300 disabled:opacity-50"
          onClick={handleNowPaymentsLimitMessage}
          disabled={isSubmitting}
        >
          <Widget
            icon={
              isSubmitting ? (
                <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin" />
              ) : settings?.nowPaymentsLimitMessageEnabled ? (
                <MdCheckBox className="h-6 w-6" />
              ) : (
                <MdCheckBoxOutlineBlank className="h-6 w-6" />
              )
            }
            title={"Show payment provider limits on Landing"}
            subtitle={
              isSettingsLoading
                ? "Loading..."
                : `${
                    settings?.nowPaymentsLimitMessageEnabled
                      ? "Visible"
                      : "Hidden"
                  }`
            }
          />
        </button>
        <button
          className="rounded-3xl text-left transition-opacity duration-300 disabled:opacity-50"
          onClick={handleEmailRequired}
          disabled={isLoadingEmailRequired}
        >
          <Widget
            icon={
              isLoadingEmailRequired ? (
                <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin" />
              ) : settings?.emailRequiredEnabled ? (
                <MdCheckBox className="h-6 w-6" />
              ) : (
                <MdCheckBoxOutlineBlank className="h-6 w-6" />
              )
            }
            title={"Enable email authorization"}
            subtitle={
              isSettingsLoading
                ? "Loading..."
                : `${settings?.emailRequiredEnabled
                  ? "On"
                  : "Off"
                }`
            }
          />
        </button>
      </div>

      <div className="mt-5 grid w-full grid-cols-1 gap-5">
        <ComplexTable />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2">
        <SalesChart />
        <UsersChart />
      </div>
    </div>
  );
};

export default Dashboard;
