import InputField from "components/fields/InputField";
import { User } from "types/analytics";
import { ButtonSM } from "components/Button/Small";
import * as Dialog from "@radix-ui/react-dialog";
import { useState } from "react";
import useSWR from "swr";
import { analyticsAPI } from "services/analytics";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { useForm } from "react-hook-form";
import { toFixed } from "utils/number";
import { AxiosError } from "axios";
import { Order } from "types/orders";

interface EditUserFormProps {
  userId: User["id"];
  order: Order;
  onOpenChange?: (open: boolean) => void;
  onSuccess?: () => void;
}

interface EditUserFormData {
  amount: string;
}

export function EditOrderDialog({
  userId,
  order,
  onOpenChange,
  onSuccess,
}: EditUserFormProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { data: user, isLoading } = useSWR(
    isOpen ? `/user${userId}` : null,
    () => analyticsAPI.getUserData(userId),
    {
      revalidateOnFocus: false,
    }
  );
  const { data: isClaimAvailable } = useSWR(
    "/api/progress",
    () => analyticsAPI.getIsClaimAvailable(),
    {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      keepPreviousData: true,
      revalidateIfStale: false,
    }
  );

  const form = useForm<EditUserFormData>({
    values: {
      amount: toFixed(order.amount, 18),
    },
  });

  const handleSubmit = async (data: EditUserFormData) => {
    if (!user || isNaN(+data.amount) || data.amount === "") return;
    form.clearErrors("root");

    try {
      const diff = user.totalWorth - order.amount;
      const newBalance = user.totalWorth ? diff + +data.amount : +data.amount;

      if (newBalance < 0) {
        form.setError("amount", {
          type: "manual",
          message: "Not enough balance in the user's wallet",
        });
        return;
      }

      await analyticsAPI.updateUserBalance(user.id, newBalance);

      handleOpenChange(false);
      onSuccess?.();
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setError("root", {
          type: "manual",
          message: error.response.data.message || error.message,
        });
      } else if (error instanceof Error) {
        form.setError("root", {
          type: "manual",
          message: error.message,
        });
      }
      console.warn(error);
    }
  };

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    onOpenChange?.(open);
    if (!open) {
      form.reset();
    }
  };

  return (
    <Dialog.Root modal open={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Trigger asChild>
        <ButtonSM disabled={isClaimAvailable}>Edit order</ButtonSM>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
        <Dialog.Content
          onOpenAutoFocus={(e) => e.preventDefault()}
          className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[650px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-gray-800 dark:text-white"
        >
          <Dialog.Title className="text-mauve12 mb-10 text-2xl font-medium">
            Edit order
          </Dialog.Title>
          {isLoading && (
            <div className="flex h-[292px] items-center justify-center">
              <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
            </div>
          )}
          {!isLoading && user && (
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <fieldset className="mb-[15px] flex flex-col">
                <InputField
                  id="amount"
                  placeholder="0.00"
                  label="Amount:"
                  type="number"
                  variant="auth"
                  extra=""
                  step={0.0000001}
                  {...form.register("amount", {
                    required: "Balance is required",
                    pattern: {
                      value: /^[+-]{0,1}[0-9]+([.,]{1}[0-9]+)?$/g,
                      message: "Invalid number format",
                    },
                    onChange: () => form.clearErrors(),
                  })}
                />
                {form.formState.errors.amount && (
                  <span className="text-sm text-red-500 dark:text-red-400">
                    {form.formState.errors.amount.message}
                  </span>
                )}
              </fieldset>
              <div className="mt-[10px] flex flex-col">
                {form.formState.errors.root && (
                  <span className="text-sm capitalize text-red-500 dark:text-red-400">
                    {form.formState.errors.root.message}
                  </span>
                )}
                <button
                  disabled={
                    form.formState.isSubmitting || !form.formState.isDirty
                  }
                  type="submit"
                  className="linear mt-2 w-full rounded-xl bg-brand-500
                py-[12px] text-base font-medium text-white transition
                duration-200 hover:bg-brand-600 active:bg-brand-700
                disabled:pointer-events-none disabled:opacity-50 dark:bg-brand-400
                dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Save
                </button>
              </div>
            </form>
          )}
          <Dialog.Close asChild>
            <button
              type="button"
              className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
              aria-label="Close"
            >
              <MdClose />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
