import axios, { AxiosInstance } from "axios";
import { API_URL } from "constants/api";
import { TOKEN_MAX_AMOUNT, TOKEN_MIN_AMOUNT } from "constants/token";
import { Round, RoundCreateRequest } from "types/rounds";
import { getAccessToken } from "utils/auth";

export class RoundsAPI {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL + "api/rounds",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    this._api.defaults.headers.put["Content-Type"] = "application/json";
  }

  async getRounds() {
    try {
      const token = getAccessToken();

      const { data } = await this._api.get<Round[]>("", {
        headers: {
          authorization: token,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return [];
  }

  async getCurrentRound(): Promise<Round | null> {
    try {
      const url = "/current";
      const { data } = await this._api.get<Round>(url);

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async createRound({
    name,
    tokenPrice,
    pool,
    minAmount = TOKEN_MIN_AMOUNT,
    maxAmount = TOKEN_MAX_AMOUNT,
    maxPurchasePerWallet = TOKEN_MAX_AMOUNT,
    startAt,
    finishAt,
  }: RoundCreateRequest) {
    const { data } = await this._api.post<Round>(
      "",
      {
        name,
        tokenPrice,
        minAmount,
        maxAmount,
        pool,
        maxPurchasePerWallet,
        startAt,
        finishAt,
      },
      {
        headers: {
          authorization: getAccessToken(),
        },
      }
    );

    return data;
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async updateRound(roundId: Round["id"], requestBody: RoundCreateRequest) {
    const { data } = await this._api.patch<Round>(`/${roundId}`, requestBody, {
      headers: {
        authorization: getAccessToken(),
      },
    });

    return data;
  }

  /**
   * @throws {Error} This method may throw an error
   */
  async deleteRound(roundId: Round["id"]) {
    const { data } = await this._api.delete<Round>(`/${roundId}`, {
      headers: {
        authorization: getAccessToken(),
      },
    });

    return data;
  }
}

export const roundsAPI = new RoundsAPI();
