import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useSWR from "swr";
import { ReferralProgram } from "./components/ReferralProgram";
import { ReferralProgramNew } from "./components/ReferralProgramNew";
import { referralAPI } from "services/referral";
import { analyticsAPI } from "services/analytics";

const ReferralProgramPage = () => {
  const {
    data: referralPrograms,
    isLoading,
    mutate: fetchRounds,
  } = useSWR("referralPrograms", () => referralAPI.getReferralPrograms());

  const { data: isClaimAvailable } = useSWR(
    "/api/progress",
    () => analyticsAPI.getIsClaimAvailable(),
    {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      keepPreviousData: true,
      revalidateIfStale: false,
    }
  );

  const handleSuccess = () => {
    fetchRounds();
  };

  return (
    <>
      {isLoading && (
        <div className="flex items-center justify-center py-4">
          <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
        </div>
      )}
      <div className="mt-3 grid h-full grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-3">
        {!isLoading && !isClaimAvailable && (
          <ReferralProgramNew
            referralPrograms={referralPrograms}
            onSuccess={handleSuccess}
          />
        )}
        {!!referralPrograms &&
          referralPrograms.length > 0 &&
          referralPrograms.map((program, i) => (
            <ReferralProgram
              key={i + program.id}
              referralPrograms={referralPrograms}
              referralProgram={program}
              onUpdate={handleSuccess}
              disabled={isClaimAvailable}
            />
          ))}
      </div>
    </>
  );
};

export default ReferralProgramPage;
