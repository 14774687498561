import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useSWR from "swr";
import { BonusProgramItem } from "./components/BonusProgramItem";
import { BonusProgramNew } from "./components/BonusProgramNew";
import { bonusAPI } from "services/bonus";
import { analyticsAPI } from "services/analytics";

const BonusProgramPage = () => {
  const {
    data: bonusPrograms,
    isLoading,
    mutate: fetchPrograms,
  } = useSWR("bonus-programs", () => bonusAPI.getBonusPrograms());
  const { data: isClaimAvailable } = useSWR(
    "/api/progress",
    () => analyticsAPI.getIsClaimAvailable(),
    {
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      keepPreviousData: true,
      revalidateIfStale: false,
    }
  );

  const handleSuccess = () => {
    fetchPrograms();
  };

  return (
    <>
      {isLoading && (
        <div className="flex items-center justify-center py-4">
          <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
        </div>
      )}
      <div className="mt-3 grid h-full grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-3">
        {!isLoading && !isClaimAvailable && (
          <BonusProgramNew onSuccess={handleSuccess} />
        )}
        {!!bonusPrograms &&
          bonusPrograms.length > 0 &&
          bonusPrograms.map((bonusProgram, i) => (
            <BonusProgramItem
              key={i + bonusProgram.id}
              bonusProgram={bonusProgram}
              onUpdate={handleSuccess}
              disabled={isClaimAvailable}
            />
          ))}
      </div>
    </>
  );
};

export default BonusProgramPage;
