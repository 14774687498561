import * as Dialog from "@radix-ui/react-dialog";
import { AxiosError } from "axios";
import clsx from "clsx";
import Card from "components/card";
import Dropdown from "components/dropdown";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BsThreeDots } from "react-icons/bs";
import { MdClose, MdDelete, MdEdit } from "react-icons/md";
import { bonusAPI } from "services/bonus";
import useSWR from "swr";
import { BonusProgram } from "types/bonusProgram";
import {
  formatDateFromString,
  formatTimeFromString,
  validateRoundDateRange,
} from "utils/date";
import { formatNumber } from "utils/number";
import { Badge } from "./Badge";
import { BonusProgramForm } from "./BonusProgramForm";
import { BonusFormScheme } from "./scheme";
import { roundsAPI } from "services/rounds";

export function BonusProgramItem({
  bonusProgram,
  onUpdate,
  disabled = false,
}: {
  bonusProgram: BonusProgram;
  onUpdate?: () => void;
  disabled?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [isEditOpen, setEditOpen] = useState(false);
  const { data: rounds, isLoading } = useSWR("rounds", () =>
    roundsAPI.getRounds()
  );

  const { data: bonusPrograms } = useSWR("bonus-programs", () =>
    bonusAPI.getBonusPrograms()
  );

  const [startAtDate, startAtTime, startAtDateUTC, startAtTimeUTC] =
    useMemo(() => {
      const data = formatDateFromString(bonusProgram.startAt);
      const dateUTC = formatDateFromString(bonusProgram.startAt, true);
      const time = formatTimeFromString(bonusProgram.startAt);
      const timeUTC = formatTimeFromString(bonusProgram.startAt, true);

      return [data, time, dateUTC, timeUTC];
    }, [bonusProgram.startAt]);

  const [finishAtDate, finishAtTime, finishAtDateUTC, finishAtTimeUTC] =
    useMemo(() => {
      const data = formatDateFromString(bonusProgram.finishAt);
      const dateUTC = formatDateFromString(bonusProgram.finishAt, true);
      const time = formatTimeFromString(bonusProgram.finishAt);
      const timeUTC = formatTimeFromString(bonusProgram.finishAt, true);
      return [data, time, dateUTC, timeUTC];
    }, [bonusProgram.finishAt]);

  const stages = useMemo(() => {
    if (!rounds) return [];

    const bonusStartDate = new Date(bonusProgram.startAt).getTime();
    const bonusEndDate = new Date(bonusProgram.finishAt).getTime();

    return rounds.filter((round) => {
      const startAt = new Date(round.startAt).getTime();
      const finishAt = new Date(round.finishAt).getTime();
      return bonusStartDate <= finishAt && bonusEndDate >= startAt;
    });
  }, [rounds, bonusProgram.startAt, bonusProgram.finishAt]);

  const form = useForm<BonusFormScheme>({
    values: {
      name: bonusProgram.name,
      percentAmount: bonusProgram.percentAmount.toString(),
      amount: bonusProgram.amount,
      fixedBonusEnabled: bonusProgram.fixedBonusEnabled,
      minPurchaseAmount: bonusProgram.minPurchaseAmount.toString(),
      minPurchaseAmountEnabled: bonusProgram.minPurchaseAmountEnabled,
      start: new Date(bonusProgram.startAt),
      end: new Date(bonusProgram.finishAt),
      description: bonusProgram.description,
    },
  });

  const handleSubmit = async (data: BonusFormScheme) => {
    try {
      if (data.end.getTime() <= data.start.getTime()) {
        form.setError("end", {
          type: "custom",
          message: "End date must be greater than start date",
        });
        return;
      }
      if (data.percentAmount === "") return;

      let isOverlap = false;
      form.clearErrors("root");
      validateRoundDateRange(
        bonusPrograms.filter((b) => b.id !== bonusProgram.id),
        data.start,
        data.end,
        () => {
          form.setError("start", {
            type: "custom",
            message: null,
          });
          form.setError("end", {
            type: "custom",
            message: null,
          });

          form.setError("root", {
            type: "custom",
            message: "The dates cannot overlap with other bonus programs",
          });
          isOverlap = true;
        }
      );

      if (isOverlap) return;

      await bonusAPI.updateBonusProgram(bonusProgram.id, {
        percentAmount: +data.percentAmount,
        amount: data.amount === "" || isNaN(data.amount) ? 0 : data.amount,
        fixedBonusEnabled: data.fixedBonusEnabled,
        minPurchaseAmount:
          data.minPurchaseAmount === "" || isNaN(+data.minPurchaseAmount)
            ? 1
            : +data.minPurchaseAmount,
        minPurchaseAmountEnabled: data.minPurchaseAmountEnabled,
        start: data.start.toISOString(),
        end: data.end.toISOString(),
        description: data.description,
      });

      onUpdate?.();
      setEditOpen(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setError("root", {
          type: "custom",
          message: error.response?.data.message,
        });
      }
    }
  };

  const handleOpenChange = (isOpen: boolean) => {
    setEditOpen(isOpen);

    if (isOpen) return;
    form.reset();
  };

  const [badgeName, badgeStyle] = useMemo(() => {
    const now = Date.now();
    const startAt = new Date(bonusProgram.startAt).getTime();
    const finishAt = new Date(bonusProgram.finishAt).getTime();
    if (now < startAt) {
      return ["Upcoming", "bg-yellow-100 text-yellow-500"];
    }
    if (now > startAt && now < finishAt) {
      return [
        "Running",
        "bg-green-500 text-white dark:bg-green-300 dark:text-green-800",
      ];
    }
    if (now > finishAt) {
      return ["Finished", "bg-red-100 text-red-500"];
    }
  }, [bonusProgram.startAt, bonusProgram.finishAt]);

  const handleDelete = () => {
    if (window.confirm("Are you sure to delete this bonus program?")) {
      bonusAPI.deleteRound(bonusProgram.id).then(() => {
        onUpdate?.();
      });
    }
  };

  return (
    <>
      <Card extra="rounded-[15px] min-h-[620px]">
        <div className="p-6">
          <div className="mb-6 flex justify-between">
            <div className="flex max-w-[calc(100%-40px)] flex-col gap-2">
              <div className="flex items-center gap-2 text-sm font-bold uppercase tracking-wider text-gray-500">
                Bonus name
                <Badge extra={badgeStyle}>{badgeName}</Badge>
              </div>
              <h2 className="line-clamp-2 break-words text-xl">
                {bonusProgram.name}
              </h2>
            </div>
            <div>
              {!disabled && (
                <Dropdown
                  button={
                    <button
                      onClick={() => setOpen(!open)}
                      className={`linear flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-xl font-bold text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10`}
                    >
                      <BsThreeDots className="h-6 w-6" />
                    </button>
                  }
                  animation={
                    "origin-top-right transition-all duration-300 ease-in-out"
                  }
                  classNames={`top-11 right-0 w-max`}
                  children={
                    <div className="z-50 flex w-max flex-col gap-2 rounded-xl bg-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                      <button
                        onClick={() => setEditOpen(true)}
                        className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600"
                      >
                        <span>
                          <MdEdit />
                        </span>
                        Edit
                      </button>
                      <button
                        onClick={handleDelete}
                        className="flex cursor-pointer items-center gap-2 text-red-600 hover:text-red-500"
                      >
                        <span>
                          <MdDelete />
                        </span>
                        Delete
                      </button>
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t p-6">
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Bonus amount</span>
            <span className="text-lg font-medium">
              {bonusProgram.percentAmount}{" "}
              <span className="text-sm font-normal text-gray-500">%</span>
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Minimum purchase to get bonus</span>
            <span className="text-lg font-medium">
              {formatNumber(bonusProgram.minPurchaseAmount)}{" "}
              <span className="text-sm font-normal text-gray-500">USD</span>
              <span
                className={clsx(
                  bonusProgram.minPurchaseAmountEnabled && "text-green-500",
                  !bonusProgram.minPurchaseAmountEnabled && "text-red-500"
                )}
              >
                {bonusProgram.minPurchaseAmountEnabled ? " [ON]" : " [OFF]"}
              </span>
            </span>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t p-6">
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">Start date</span>
            <span className="text-lg font-medium">{startAtDate}</span>
            <span className="text-sm">{startAtTime}</span>
          </div>
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">End date</span>
            <span className="text-lg font-medium">{finishAtDate}</span>
            <span className="text-sm">{finishAtTime}</span>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t p-6">
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">Start date (UTC)</span>
            <span className="text-lg font-medium">{startAtDateUTC}</span>
            <span className="text-sm">{startAtTimeUTC}</span>
          </div>
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">End date (UTC)</span>
            <span className="text-lg font-medium">{finishAtDateUTC}</span>
            <span className="text-sm">{finishAtTimeUTC}</span>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t p-6">
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">Bonus in this stage</span>
            <span className="text-lg font-medium">
              {formatNumber(bonusProgram.given)}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">Total gave bonus</span>
            <span className="text-lg font-medium">
              {formatNumber(bonusProgram.totalGiven)}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-2 border-t p-6">
          <div className="flex flex-col pr-4">
            <span className="mb-1 text-gray-500">Stage Sale</span>
            <span
              className="max-h-[50px] overflow-y-auto text-lg font-medium"
              title={stages.map((el) => el.name).join(", ")}
            >
              {isLoading && "Loading..."}
              {!isLoading && stages.length <= 0 && "No stages available"}
              {!isLoading &&
                stages.length > 0 &&
                stages.map((el) => (
                  <div key={el.id}>
                    {el.name} <br />
                  </div>
                ))}
            </span>
          </div>
          <div className="flex flex-col">
            <span className="mb-1 text-gray-500">Description</span>
            <span className="max-h-[90px] overflow-auto truncate text-lg font-medium">
              {bonusProgram.description || "No description"}
            </span>
          </div>
        </div>
      </Card>
      <Dialog.Root open={isEditOpen} onOpenChange={handleOpenChange} modal>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-gray-800 dark:text-white">
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <Dialog.Title className="text-mauve12 mb-10 text-[17px] font-medium">
                Edit stage
              </Dialog.Title>
              <div className="max-h-[480px] grow overflow-y-auto overflow-x-hidden px-px">
                <FormProvider {...form}>
                  <BonusProgramForm />
                </FormProvider>
              </div>
              <div className="mt-[10px] flex flex-col">
                {form.formState.errors.root && (
                  <span className="text-sm capitalize text-red-500 dark:text-red-400">
                    {form.formState.errors.root.message}
                  </span>
                )}
                <button
                  type="submit"
                  className="linear mt-2 w-full rounded-xl bg-brand-500
                  py-[12px] text-base font-medium text-white transition
                  duration-200 hover:bg-brand-600 active:bg-brand-700
                  disabled:pointer-events-none disabled:opacity-50 dark:bg-brand-400
                  dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled={
                    form.formState.isSubmitting || !form.formState.isDirty
                  }
                >
                  {form.formState.isSubmitting ? "Saving..." : "Save changes"}
                </button>
              </div>
            </form>
            <Dialog.Close asChild>
              <button
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
