// Custom components
import { InputHTMLAttributes, forwardRef } from "react";
import { ControllerRenderProps } from "react-hook-form";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  extra: string;
  placeholder: string;
  variant: string;
  state?: string;
  disabled?: boolean;
  type?: string;
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
  const { label, id, extra, type, placeholder, variant, state, disabled, ...inputProps } =
    props;

  return (
    <div className={`${extra}`}>
      {!!label && (
        <label
          htmlFor={id}
          className={`text-sm text-navy-700 dark:text-white ${
            variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"
          }`}
        >
          {label}
        </label>
      )}
      <input
        disabled={disabled}
        type={type}
        id={id}
        placeholder={placeholder}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none transition-opacity duration-300 ${
          disabled === true
            ? "opacity-50"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-200 dark:!border-white/10 dark:text-white"
        }`}
        ref={ref}
        {...inputProps}
      />
    </div>
  );
})

export default InputField;
