import axios, { AxiosInstance } from "axios";
import { API_URL } from "constants/api";
import { ChartGroupBy, ChartType } from "types/analytics";
import { Order } from "types/orders";
import { ChartDataValue, PaginatedMetaDocumented } from "types/pagination";
import { getAccessToken } from "utils/auth";

export class OrdersAPI {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL + "api/orders",
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    this._api.defaults.headers.put["Content-Type"] = "application/json";
  }

  async getOrders(
    page: number | undefined = 1,
    limit: number | undefined = 20,
    search: string | undefined = "",
    searchBy: string | undefined = "",
    sortBy: string | undefined = "createdAt:ASC"
  ): Promise<PaginatedMetaDocumented<Order> | null> {
    try {
      const { data } = await this._api.get<PaginatedMetaDocumented<Order>>("", {
        params: {
          page,
          limit,
          sortBy,
          search,
          searchBy,
        },
        headers: {
          authorization: getAccessToken(),
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async loadCSV() {
    try {
      const { data } = await this._api.get("/export/csv", {
        headers: {
          authorization: getAccessToken(),
        },
        responseType: "blob",
      });

      const virtualLink = window.document.createElement("a");
      virtualLink.href = window.URL.createObjectURL(data);
      virtualLink.download = "orders.csv";
      virtualLink.click();
    } catch (error) {
      console.warn(error);
    }
  }

  async loadXLS() {
    try {
      const { data } = await this._api.get("/export/xls", {
        headers: {
          authorization: getAccessToken(),
        },
        responseType: "blob",
      });

      const virtualLink = window.document.createElement("a");
      virtualLink.href = window.URL.createObjectURL(data);
      virtualLink.download = "orders.xlsx";
      virtualLink.click();
      virtualLink.remove();
    } catch (error) {
      console.warn(error);
    }
  }

  async getChartData(
    fromDate: string,
    toDate: string,
    groupBy: ChartGroupBy,
    type?: ChartType
  ) {
    try {
      let url = `/chart`;

      switch (type) {
        case ChartType.USD:
          url = `/chart/usd`;
          break;
        case ChartType.Tokens:
          url = `/chart/tokens`;
          break;
        default:
          url = `/chart`;
          break;
      }

      const { data } = await this._api.get<ChartDataValue[]>(url, {
        params: {
          fromDate,
          toDate,
          groupBy,
        },
        headers: {
          authorization: getAccessToken(),
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }
}

export const ordersAPI = new OrdersAPI();
