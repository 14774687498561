
// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/adminSEttings";
import NFTMarketplace from "views/admin/saleRound";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import { IoMdGift } from "react-icons/io";
import {
  MdDiversity1,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdPerson,
  MdOutlineFilterFrames
} from "react-icons/md";
import ReferralProgramPage from "views/admin/referralProgram";
import BonusProgram from "views/admin/bonusProgram";
import Banners from 'views/admin/banners';

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Sale round",
    layout: "/admin",
    path: "sale-rounds",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Bonus Program",
    layout: "/admin",
    path: "bonus-program",
    icon: <IoMdGift className="h-6 w-6" />,
    component: <BonusProgram />,
    secondary: true,
  },
  {
    name: "Referral program",
    layout: "/admin",
    path: "referral",
    icon: <MdDiversity1 className="h-6 w-6" />,
    component: <ReferralProgramPage />,
  },
  {
    name: "Banners",
    layout: "/admin",
    path: "banners",
    icon: <MdOutlineFilterFrames className="h-6 w-6" />,
    component: <Banners />,
  },
  {
    name: "Admin settings",
    layout: "/admin",
    path: "admin-settings",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
];
export default routes;
