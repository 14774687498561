import axios, { AxiosInstance } from "axios";
import { API_URL } from "constants/api";
import { User } from "types/analytics";
import { getAccessToken } from "utils/auth";

export class UsersAPI {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    this._api.defaults.headers.put["Content-Type"] = "application/json";
  }

  async setUserIsKicked(userId: User['id'], kicked: boolean) {
    await this._api.patch(
      `/api/users/kick`,
      { userId, kicked },
      {
        headers: { authorization: getAccessToken() },
      }
    );
  }
}

export const usersAPI = new UsersAPI();
