import axios, { AxiosInstance } from "axios";
import { API_URL } from "constants/api";
import { AnalyticsResponse, User, Settings } from "types/analytics";
import { ChartData } from "types/pagination";
import { getAccessToken } from "utils/auth";

export class AnalyticsAPI {
  private _api: AxiosInstance;
  constructor() {
    this._api = axios.create({
      baseURL: API_URL,
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    this._api.defaults.headers.put["Content-Type"] = "application/json";
  }

  async getIsClaimAvailable() {
    try {
      const url = "/api/sale/progress";
      const { data } = await this._api.get<{
        claimAvailable: boolean;
      }>(url);

      return data.claimAvailable;
    } catch (error) {
      console.warn(error);
    }
  }

  async getAnalytics() {
    try {
      const url = "/api/analytics";

      const accessToken = getAccessToken();

      const { data } = await this._api.get<AnalyticsResponse>(url, {
        headers: {
          authorization: accessToken,
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
  }

  async getUsersChartData(fromDate: string, toDate: string, groupBy: string) {
    try {
      const { data } = await this._api.get<ChartData[]>("/api/users/chart", {
        params: {
          fromDate,
          toDate,
          groupBy,
        },
        headers: {
          authorization: getAccessToken(),
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async getUserData(userId: string) {
    try {
      const { data } = await this._api.get<User>(`/api/users/${userId}`, {
        headers: {
          authorization: getAccessToken(),
        },
      });

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async getSettings() {
    const { data } = await this._api.get<Settings>("/api/settings", {
      headers: { authorization: getAccessToken() },
    });
    return data;
  }

  async updateSettings(settings: Partial<Omit<Settings, "id" | "updatedAt">>) {
    try {
      const { data } = await this._api.patch<Settings>(
        "/api/settings",
        settings,
        {
          headers: { authorization: getAccessToken() },
        }
      );

      return data;
    } catch (error) {
      console.warn(error);
    }
    return null;
  }

  async updateUserBalance(userId: string, balance: number) {
    const { data } = await this._api.patch<User>(
      `/api/balances`,
      { userId, amount: balance },
      {
        headers: { authorization: getAccessToken() },
      }
    );

    return data;
  }
}

export const analyticsAPI = new AnalyticsAPI();
