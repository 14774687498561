import * as Dialog from "@radix-ui/react-dialog";
import { FormProvider, UseFormReturn } from "react-hook-form";
import { MdClose } from "react-icons/md";
import Switch from "components/switch";
import { BannerFormScheme } from "./scheme";
import TextArea from 'components/fields/TextArea';

interface IProps {
  handleSubmit: (data: BannerFormScheme) => void;
  form: UseFormReturn<BannerFormScheme, any, undefined>;
  isOpen: boolean;
  isOpenChange: (isOpen: boolean) => void;
  isCreate?: boolean;
}

export const BannerForm: React.FC<IProps> = ({
  handleSubmit,
  form,
  isOpen,
  isOpenChange,
  isCreate,
}) => {
  const BANNER_TEXT_MAX_LENGTH = 100

  return (
    <Dialog.Root open={isOpen} onOpenChange={isOpenChange} modal>
      <Dialog.Portal>
        <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[15px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-[#0b1437] dark:text-white">
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Dialog.Title className="text-mauve12 mb-5 text-[17px] font-medium">
              {isCreate ? "Create banner" : "Edit banner"}
            </Dialog.Title>
            <FormProvider {...form}>
              <div className="max-h-[480px] grow overflow-auto">
                <fieldset className="mb-[15px] ml-1.5 flex">
                  <label
                    htmlFor="doubleReferralBonus"
                    className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
                  >
                    Show banner
                  </label>
                  <Switch
                    disabled={isCreate}
                    label=""
                    id="enabled"
                    {...form.register("isActive")}
                  />
                </fieldset>
                <fieldset className="mb-[15px] flex flex-col">
                  <TextArea
                    variant="auth"
                    state={form.formState.errors.text && "error"}
                    extra="w-full mb-3"
                    label="Banner text"
                    placeholder="Banner text"
                    id="text"
                    type="text"
                    hint='*to insert the link write down text in this format [link-preview](http://link)'
                    {...form.register("text", {
                      required: "This field is required",
                      maxLength: {
                        value: BANNER_TEXT_MAX_LENGTH,
                        message: `The text must be less than ${BANNER_TEXT_MAX_LENGTH} characters`
                      }
                    })}
                  />
                  {form.formState.errors.text && (
                    <span className="text-sm text-red-500 dark:text-red-400">
                      {form.formState.errors.text.message}
                    </span>
                  )}
                </fieldset>
                <fieldset className="mb-[15px] ml-1.5 flex">
                  <label
                    htmlFor="textColor"
                    className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
                  >
                    Choose color for text
                  </label>
                  <div className="flex rounded-full border border-gray-200 dark:border-[#ffffff33]">
                    <input
                      {...form.register("textColor")}
                      type="color"
                      id="textColor"
                      className="h-7 w-7 cursor-pointer overflow-hidden rounded-full border-none bg-[transparent]"
                    />
                  </div>
                </fieldset>
                <fieldset className="mb-[15px] ml-1.5 flex">
                  <label
                    htmlFor="bannerColor"
                    className="mb-2 mr-auto text-sm font-medium text-navy-700 dark:text-white"
                  >
                    Choose color for background
                  </label>
                  <div className="flex rounded-full border border-gray-200 dark:border-[#ffffff33]">
                    <input
                      {...form.register("backgroundColor")}
                      type="color"
                      id="bannerColor"
                      className="h-7 w-7 cursor-pointer overflow-hidden rounded-full border-none bg-[transparent]"
                    />
                  </div>
                </fieldset>
              </div>
            </FormProvider>
            <div className="mt-[25px] flex justify-end">
              <button
                type="submit"
                className="linear mt-2 w-full rounded-xl bg-brand-500
                        py-[12px] text-base font-medium text-white transition
                        duration-200 hover:bg-brand-600 active:bg-brand-700
                        dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300
                        dark:active:bg-brand-200"
                disabled={form.formState.isSubmitting}
              >
                Save changes
              </button>
            </div>
            <Dialog.Close asChild>
              <button
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
