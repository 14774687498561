import * as Dialog from "@radix-ui/react-dialog";
import { AxiosError } from "axios";
import Card from "components/card";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { MdAddCircleOutline } from "react-icons/md";
import { BannerFormScheme } from "./scheme";
import { bannerAPI } from "services/banners";
import { BannerForm } from "./BannerForm";

interface IProps {
  onSuccess: () => void;
}

export const BannerNew: React.FC<IProps> = ({ onSuccess }) => {
  const [isOpenForm, setIsOpenForm] = useState(false);

  const form = useForm<BannerFormScheme>({
    defaultValues: {
      text: "",
      isActive: false,
      backgroundColor: "#111C43",
      textColor: "#ffffff",
    },
  });

  const handleOpenFormChange = (isOpen: boolean) => {
    setIsOpenForm(isOpen);

    if (isOpen) return;
    form.reset();
  };

  const handleSubmit = async (data: BannerFormScheme) => {
    try {
      await bannerAPI.createBanner({
        text: data.text,
        textColor: data.textColor,
        backgroundColor: data.backgroundColor,
      });

      onSuccess();
      handleOpenFormChange(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setError("root", {
          type: "custom",
          message: error.response?.data.message,
        });
      }
    }
  };

  return (
    <>
      <Card
        extra="cursor-pointer rounded-[15px] min-h-[620px]"
        onClick={() => setIsOpenForm(true)}
      >
        <div className="flex grow flex-col items-center justify-center gap-6 p-6">
          <MdAddCircleOutline className="h-32 w-32 text-gray-600" />
          <div className="text-center text-2xl font-bold uppercase">
            Create banner
          </div>
        </div>
      </Card>
      <BannerForm
        handleSubmit={handleSubmit}
        form={form}
        isOpen={isOpenForm}
        isOpenChange={handleOpenFormChange}
        isCreate
      />
    </>
  );
};
