import { WEEKS_IN_YEAR } from "constants/time";
import { ChartGroupBy } from "types/analytics";
import { BonusProgram } from "types/bonusProgram";
import { ReferralProgram } from "types/referralProgram";
import { Round } from "types/rounds";

export const shortMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formatDateFromString = (date: string, isUTC = false) => {
  const dateObj = new Date(date);

  if (isUTC) {
    const day = dateObj.getUTCDate().toString().padStart(2, "0");
    const month = shortMonthNames[dateObj.getUTCMonth()];
    const year = dateObj.getUTCFullYear();

    return `${day} ${month}, ${year}`;
  }

  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = dateObj.toLocaleString("en-US", {
    month: "short",
  });
  const year = dateObj.getFullYear();

  return `${day} ${month}, ${year}`;
};

export const formatTimeFromString = (data: string, isUTC = false) => {
  const dateObj = new Date(data);

  if (isUTC) {
    return dateObj.toISOString().slice(11, 16);
  }

  const hours = dateObj.getHours().toString().padStart(2, "0");
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

function _getTimeZoneOffsetInMs() {
  return new Date().getTimezoneOffset() * -60 * 1000;
}

export const toDateTimeLocalString = (date: Date) => {
  const dateWithOffset = new Date(date.getTime() + _getTimeZoneOffsetInMs());
  return dateWithOffset.toISOString().slice(0, 16);
};

export const getTimeFrom = (date: Date, isUTC = false) => {
  if (isUTC) {
    return date.toISOString().slice(11, 16);
  }

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const validateRoundDateRange = (
  objects: Round[] | BonusProgram[],
  startDate: Date,
  endDate: Date,
  onOverlap: () => void
) => {
  for (let i = 0; i < objects.length; i++) {
    if (
      startDate.getTime() <= new Date(objects[i].finishAt).getTime() &&
      endDate.getTime() >= new Date(objects[i].startAt).getTime()
    ) {
      onOverlap();
    }
  }
};

export const validateReferralDateRange = (
  objects: ReferralProgram[],
  startDate: Date,
  endDate: Date,
  onOverlap: () => void
) => {
  for (let i = 0; i < objects.length; i++) {
    if (
      startDate.getTime() <= new Date(objects[i].end).getTime() &&
      endDate.getTime() >= new Date(objects[i].start).getTime()
    ) {
      onOverlap();
    }
  }
};

const getDateWeek = (date: Date) => {
  const currentDate = date;
  const januaryFirst = new Date(currentDate.getFullYear(), 0, 1);
  const daysToNextMonday =
    januaryFirst.getDay() === 1 ? 0 : (7 - januaryFirst.getDay()) % 7;
  const nextMonday = new Date(
    currentDate.getFullYear(),
    0,
    januaryFirst.getDate() + daysToNextMonday
  );

  return currentDate < nextMonday
    ? WEEKS_IN_YEAR
    : currentDate > nextMonday
    ? Math.ceil(
        (currentDate.getTime() - nextMonday.getTime()) / (24 * 3600 * 1000) / 7
      )
    : 1;
};

export const formatDateByGroup = (date: string, groupBy: ChartGroupBy) => {
  const d = new Date(date);
  switch (groupBy) {
    case ChartGroupBy.Hour:
      return `${d.getUTCHours()}:00`;
    case ChartGroupBy.Day:
      // return d.toLocaleDateString();
      return d.toLocaleDateString();
    case ChartGroupBy.Week:
      return `Week ${getDateWeek(d)}`;
    case ChartGroupBy.Month:
      return shortMonthNames[d.getMonth()];
    case ChartGroupBy.Year:
      return d.getFullYear().toString();
  }
};

export function getMonday(d: Date) {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

export const calcDateRangeByPeriod = (period: ChartGroupBy): [
  string,
  string,
  ChartGroupBy
] => {
  const toDate = new Date();
    let groupBy: ChartGroupBy = ChartGroupBy.Day;
    let fromDate = new Date();
    fromDate.setHours(0);
    fromDate.setMinutes(0);
    fromDate.setSeconds(0);
    switch (period) {
      case ChartGroupBy.Day:
        groupBy = ChartGroupBy.Day;
        fromDate.setDate(fromDate.getDate() - 15);
        break;
      case ChartGroupBy.Week:
        fromDate.setMonth(fromDate.getMonth() - 3, 1);
        groupBy = ChartGroupBy.Week;
        break;
      case ChartGroupBy.Month:
        fromDate.setFullYear(fromDate.getFullYear() - 1);
        groupBy = ChartGroupBy.Month;
        break;
      case ChartGroupBy.Year:
        fromDate.setFullYear(2024, 0, 1);
        groupBy = ChartGroupBy.Year;
        break;        
    }
    return [
      toDateTimeLocalString(fromDate),
      toDateTimeLocalString(toDate),
      groupBy,
    ];
};
