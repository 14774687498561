import clsx from "clsx";
import { ButtonHTMLAttributes, forwardRef } from "react";

const variants = {
  primary:
    "bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 text-white dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200",
  secondary: "bg-gray-200 hover:bg-gray-300 active:bg-gray-400 text-[#000] dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 dark:active:bg-gray-600",
};

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: keyof typeof variants;
}

export const ButtonSM = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, variant = "primary", ...props }, ref) => {
    return (
      <button
        type="button"
        ref={ref}
        className={clsx(
          `flex items-center gap-2 rounded-xl 
                  px-3 py-0.5 text-sm font-medium transition
                  duration-200 
                  disabled:pointer-events-none disabled:opacity-50`,
          variants[variant]
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);
