import { createColumnHelper } from "@tanstack/react-table";
import { DAY_IN_MS } from "constants/time";
import {
  MdCheck,
  MdCheckCircle,
  MdClose,
  MdOutlineError,
  MdPending,
} from "react-icons/md";
import { Order } from "types/orders";
import { formatDateFromString, formatTimeFromString } from "utils/date";
import { toFixed } from "utils/number";
import { UserDialog } from "./UserDialog";
import { EditOrderDialog } from "./EditOrderDialog";
import clsx from "clsx";

const columnHelper = createColumnHelper<Order>();

export const columns = [
  columnHelper.accessor("user.wallet", {
    id: "address",
    filterFn: "includesString",
    enableSorting: false,
    header: () => (
      <p className="min-w-[350px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        Address
      </p>
    ),
    cell: (info) => (
      <p
        onClick={() => info.table.setGlobalFilter(info.getValue())}
        className="cursor-pointer text-sm font-bold text-navy-700 dark:text-white"
      >
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("user", {
    id: "user",
    enableSorting: false,
    header: () => (
      <p className="w-[90px] min-w-[50px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        Profile
      </p>
    ),
    cell: (info) => (
      <p className="-my-2 text-sm font-bold text-navy-700 dark:text-white">
        <UserDialog
          userId={info.getValue().id}
          onOpenChange={(isOpen) => {
            info.table.setColumnFilters([
              {
                id: "editUser",
                value: isOpen,
              },
            ]);
          }}
        />
      </p>
    ),
  }),
  columnHelper.accessor("user.email", {
    id: "email",
    enableSorting: false,
    header: () => (
      <p className="min-w-[250px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        Email
      </p>
    ),
    cell: (info) => (
      <p
        onClick={() => info.table.setGlobalFilter(info.getValue())}
        className="cursor-pointer text-sm font-bold text-navy-700 dark:text-white"
      >
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("status", {
    id: "status",
    enableSorting: false,
    header: () => (
      <p className="min-w-[150px] text-sm font-bold text-gray-600 dark:text-white">
        STATUS
      </p>
    ),
    cell: (info) => (
      <div className="flex items-center">
        {info.getValue() === "PAID" ? (
          <MdCheckCircle className="me-1 text-green-500 dark:text-green-300" />
        ) : info.getValue() === "WAITING_PAYMENT" ? (
          <MdPending className="dark:amber-red-300 me-1 text-amber-500" />
        ) : info.getValue() === "EXPIRED" ? (
          <MdOutlineError className="me-1 text-red-500 dark:text-red-300" />
        ) : null}
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      </div>
    ),
  }),
  columnHelper.accessor("amount", {
    id: "amount",
    header: () => (
      <p className="min-w-[140px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        Amount of token
      </p>
    ),
    cell: (info) => (
      <div className="flex items-center gap-1 text-sm font-bold">
        {toFixed(info.getValue(), 7)}{" "}
        <span className="text-sm font-normal text-gray-500">
          ~${(info.getValue() * info.row.original.tokenPrice).toFixed(2)}
        </span>
      </div>
    ),
  }),
  columnHelper.accessor("tokenPrice", {
    id: "tokenPrice",
    header: () => (
      <p className="min-w-[120px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        Price per token
      </p>
    ),
    cell: (info) => (
      <div className="flex items-center gap-1 text-sm font-bold">
        ${toFixed(info.getValue(), 7)}
      </div>
    ),
  }),
  columnHelper.accessor("bonusAmount", {
    id: "bonusAmount",
    header: () => (
      <p className="min-w-[120px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        Bonus amount
      </p>
    ),
    cell: (info) => (
      <div className="flex items-center gap-1 text-sm font-bold">
        {toFixed(info.getValue(), 7)}{" "}
        <span className="text-sm font-normal text-gray-500">
          {info.row.original.bonusPercent}%
        </span>
      </div>
    ),
  }),
  columnHelper.accessor("totalUserWorth", {
    id: "totalUserWorth",
    header: () => (
      <p className="min-w-[150px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        Total wallet worth
      </p>
    ),
    cell: (info) => (
      <div className="flex items-center gap-1 text-sm font-bold">
        {Math.floor(info.getValue() * 100) / 100}
      </div>
    ),
  }),
  columnHelper.accessor("referralEmail", {
    id: "refEmail",
    header: () => (
      <p className="min-w-[250px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        Referral email
      </p>
    ),
    cell: (info) => (
      <div className="flex items-center gap-1 text-sm font-bold">
        {!!info.getValue() ? (
          info.getValue()
        ) : (
          <span className="font-medium text-gray-500">None</span>
        )}
      </div>
    ),
  }),
  columnHelper.accessor("round", {
    id: "round",
    header: () => (
      <p className="min-w-[140px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        Sale round
      </p>
    ),
    cell: (info) => (
      <p
        className="max-w-[140px] truncate text-sm font-bold text-navy-700 dark:text-white"
        title={info.getValue()?.name}
      >
        {info.getValue()?.name ? (
          info.getValue().name
        ) : (
          <span className="font-medium text-gray-500">None</span>
        )}
      </p>
    ),
  }),
  columnHelper.accessor("createdAt", {
    id: "isEligibleForRefund",
    filterFn: "includesString",
    enableSorting: false,
    header: () => (
      <p className="min-w-[140px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        Eligible for refund
      </p>
    ),
    cell: (info) => (
      <div className="flex items-center text-sm font-bold">
        {new Date(info.getValue()).getTime() + DAY_IN_MS > Date.now() &&
        info.row.original.status === "PAID" ? (
          <>
            <MdCheck className="me-1 text-green-500 dark:text-green-300" />
            <p>Yes</p>
          </>
        ) : (
          <>
            <MdClose className="me-1 text-red-500 dark:text-red-300" />
            <p>No</p>
          </>
        )}
      </div>
    ),
  }),
  columnHelper.accessor("createdAt", {
    id: "created_at",
    header: () => (
      <p className="min-w-[150px] text-sm font-bold text-gray-600 dark:text-white">
        DATE
      </p>
    ),
    cell: (info) => (
      <p className="text-sm font-bold text-navy-700 dark:text-white">
        {formatDateFromString(info.getValue())}{" "}
        {formatTimeFromString(info.getValue())}
      </p>
    ),
  }),
  columnHelper.accessor("id", {
    id: "id",
    header: () => (
      <p className="min-w-[300px] text-sm font-bold text-gray-600 dark:text-white">
        Order ID
      </p>
    ),
    cell: (info) => (
      <p
        className="cursor-pointer text-sm font-bold text-navy-700 dark:text-white"
        onClick={() => info.table.setGlobalFilter(info.getValue())}
      >
        {info.getValue()}
      </p>
    ),
  }),
  columnHelper.accessor("nowPaymentsPaymentId", {
    id: "nowPaymentsPaymentId",
    header: () => (
      <p className="min-w-[110px] text-sm font-bold text-gray-600 dark:text-white">
        NowPayments ID
      </p>
    ),
    cell: (info) => (
      <p
        className={clsx(
          "text-sm font-bold text-navy-700 dark:text-white",
          info.getValue() && "cursor-pointer "
        )}
        onClick={() => {
          if (!info.getValue()) return;

          info.table.setGlobalFilter(info.getValue());
        }}
      >
        {info.getValue() ?? (
          <span className="font-medium text-gray-500">None</span>
        )}
      </p>
    ),
  }),
  columnHelper.accessor("user", {
    id: "editUser",
    enableSorting: false,
    header: () => (
      <p className="w-[90px] min-w-[50px] text-sm font-bold uppercase text-gray-600 dark:text-white">
        Actions
      </p>
    ),
    cell: (info) => (
      <p className="-my-2 text-sm font-bold text-navy-700 dark:text-white">
        <EditOrderDialog
          order={info.row.original}
          userId={info.getValue().id}
          onOpenChange={(isOpen) => {
            info.table.setColumnFilters([
              {
                id: "editUser",
                value: isOpen,
              },
            ]);
          }}
        />
      </p>
    ),
  }),
];
