import clsx from "clsx";
import { ButtonHTMLAttributes } from "react";

export const SuccessButton = ({
  children,
  className,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      type="button"
      className={clsx(`flex items-center gap-2 rounded-xl bg-green-500
                  px-5 py-2 text-base font-medium text-white transition
                  duration-200 hover:bg-green-600 active:bg-green-700
                  disabled:pointer-events-none disabled:opacity-50 dark:bg-green-400
                  dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200`, className)}
      {...props}
    >
      {children}
    </button>
  );
};
