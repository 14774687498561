import Card from "components/card";
import Dropdown from "components/dropdown";
import { useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import clsx from "clsx";
import { MdDelete, MdEdit } from "react-icons/md";
import { IBanner } from "types/banners";
import { bannerAPI } from "services/banners";
import { useForm } from "react-hook-form";
import { BannerFormScheme } from "./scheme";
import { BannerForm } from "./BannerForm";
import { AxiosError } from "axios";
import Markdown from 'markdown-to-jsx';

interface IProps {
  banner: IBanner;
  onSuccess: () => void;
}

export const Banner: React.FC<IProps> = ({ banner, onSuccess }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const [isOpenForm, setIsOpenForm] = useState(false);

  const form = useForm<BannerFormScheme>({
    defaultValues: {
      text: banner.text,
      isActive: banner.isActive,
      backgroundColor: banner.backgroundColor,
      textColor: banner.textColor,
    },
  });

  useEffect(() => {
    form.reset({
      text: banner.text,
      isActive: banner.isActive,
      backgroundColor: banner.backgroundColor,
      textColor: banner.textColor,
    });
  }, [banner, form]);

  const handleOpenFormChange = (isOpen: boolean) => {
    setIsOpenForm(isOpen);

    if (isOpen) return;
    form.reset();
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure to delete this banner?")) {
      setIsOpenDropdown(false);
      bannerAPI.deleteBanner(banner.id).then(() => {
        onSuccess();
      });
    }
  };

  const handleUpdate = async (data: BannerFormScheme) => {
    try {
      await bannerAPI.updateBanner(
        {
          text: data.text,
          textColor: data.textColor,
          backgroundColor: data.backgroundColor,
          isActive: data.isActive,
        },
        banner.id
      );

      onSuccess();
      handleOpenFormChange(false);
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setError("root", {
          type: "custom",
          message: error.response?.data.message,
        });
      }
    }
  };

  const onClickEditHandler = () => {
    setIsOpenForm(true);
    setIsOpenDropdown(false);
  };

  const BannerLink: React.FC<{ children: React.ReactNode }> = ({ children, ...props }) => <a target='_blanc' {...props}>{children}</a>

  return (
    <>
      <Card extra="rounded-[15px] min-h-[620px]">
        <div className="p-6">
          <div className="mb-6 flex justify-between">
            <div>
              <Dropdown
                open={isOpenDropdown}
                button={
                  <button
                    onClick={() => setIsOpenDropdown(!isOpenDropdown)}
                    className={`linear flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-xl font-bold text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10`}
                  >
                    <BsThreeDots className="h-6 w-6" />
                  </button>
                }
                animation={
                  "origin-top-right transition-all duration-300 ease-in-out"
                }
                classNames={`top-11 right-0 w-max`}
                children={
                  <div className="z-50 flex w-max flex-col gap-2 rounded-xl bg-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                    <button
                      onClick={onClickEditHandler}
                      className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600"
                    >
                      <span>
                        <MdEdit />
                      </span>
                      Edit
                    </button>
                    <button
                      onClick={handleDelete}
                      className="flex cursor-pointer items-center gap-2 text-red-600 hover:text-red-500"
                    >
                      <span>
                        <MdDelete />
                      </span>
                      Delete
                    </button>
                  </div>
                }
              />
            </div>
          </div>
          <div className="flex items-center gap-4">
            <span className="text-gray-500">Visibility:</span>
            <span
              className={clsx(
                banner.isActive && "text-green-500",
                !banner.isActive && "text-red-500"
              )}
            >
              {banner.isActive ? " [VISIBLE]" : " [HIDDEN]"}
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 border-t p-6">
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Banner text</span>
            <span className="text-lg font-medium text-ellipsis overflow-hidden">
              <Markdown options={{
                overrides: {
                  a: {
                    component: BannerLink,
                    props: {
                      className: "underline"
                    }
                  }
                }
              }}>{banner.text}</Markdown>
            </span>
          </div>
        </div>
        <div className="grid grid-cols-1 border-t p-6">
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Banner text color</span>
            <div className="flex w-full items-center justify-between">
              <span className="text-lg font-medium">{banner.textColor}</span>
              <div className="flex rounded-full border border-gray-200 dark:border-[#ffffff33]">
                <input
                  type="color"
                  value={banner.textColor}
                  disabled
                  className="h-7 w-7 !cursor-default overflow-hidden rounded-full border-none bg-[transparent]"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 border-t p-6">
          <div className="flex flex-col gap-1">
            <span className="text-gray-500">Banner background color</span>
            <div className="flex w-full items-center justify-between">
              <span className="text-lg font-medium">
                {banner.backgroundColor}
              </span>
              <div className="flex rounded-full border border-gray-200 dark:border-[#ffffff33]">
                <input
                  type="color"
                  value={banner.backgroundColor}
                  disabled
                  className="h-7 w-7 !cursor-default overflow-hidden rounded-full border-none bg-[transparent]"
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <BannerForm
        handleSubmit={handleUpdate}
        form={form}
        isOpen={isOpenForm}
        isOpenChange={handleOpenFormChange}
      />
    </>
  );
};
