import { ButtonSM } from "components/Button/Small";
import Card from "components/card";
import Dropdown from "components/dropdown";
import { useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdArrowDropDown } from "react-icons/md";
import { analyticsAPI } from "services/analytics";
import useSWR from "swr";
import { ChartGroupBy } from "types/analytics";
import { calcDateRangeByPeriod, formatDateByGroup } from "utils/date";
import { barChartOptions } from "variables/charts";

export function UsersChart() {
  const [isOpen, setIsOpen] = useState(false);
  const [period, setPeriod] = useState<ChartGroupBy>(ChartGroupBy.Day);

  const [fromDate, toDate, groupBy] = useMemo(() => {
    return calcDateRangeByPeriod(period);
  }, [period]);

  const { data, isLoading } = useSWR(
    `/users/chart?fromDate=${fromDate}&toDate=${toDate}&groupBy=${groupBy}`,
    () => analyticsAPI.getUsersChartData(fromDate, toDate, groupBy)
  );

  return (
    <Card extra={"w-full h-[800px]  md:h-[500px] px-6 pb-6 rounded-[15px]"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="flex items-center gap-2">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Users chart
          </div>
        </div>
        <Dropdown
          animation={"origin-top-right transition-all duration-300 ease-in-out"}
          button={
            <ButtonSM>
              <span className="capitalize">{period}</span>
              <MdArrowDropDown />
            </ButtonSM>
          }
          classNames={`top-6 right-0 w-max`}
          open={isOpen}
          onOpenChange={setIsOpen}
        >
          <div className="z-50 w-max rounded-xl bg-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <button
              onClick={() => {
                setPeriod(ChartGroupBy.Day);
                setIsOpen(false);
              }}
              className="flex cursor-pointer items-center gap-2 text-gray-600 hover:text-[#000]"
            >
              Day
            </button>
            <button
              onClick={() => {
                setPeriod(ChartGroupBy.Week);
                setIsOpen(false);
              }}
              className="mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 transition-colors duration-200 hover:text-[#000]"
            >
              Week
            </button>
            <button
              onClick={() => {
                setPeriod(ChartGroupBy.Month);
                setIsOpen(false);
              }}
              className="mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 transition-colors duration-200 hover:text-[#000]"
            >
              Month
            </button>
            <button
              onClick={() => {
                setPeriod(ChartGroupBy.Year);
                setIsOpen(false);
              }}
              className="mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 transition-colors duration-200 hover:text-[#000]"
            >
              Year
            </button>
          </div>
        </Dropdown>
      </div>
      <div className="h-[700px]">
        {isLoading && (
          <div className="flex h-full items-center justify-center">
            <AiOutlineLoading3Quarters className="h-10 w-10 animate-spin" />
          </div>
        )}
        {!isLoading && data && (
          <Chart
            series={[
              {
                name: "Users",
                data: data.map((el) => +el.count),
              },
            ]}
            options={
              {
                ...barChartOptions,
                yaxis: {
                  labels: {
                    formatter: function (val: number) {
                      return val.toFixed(0);
                    },
                  },
                },
                xaxis: {
                  ...barChartOptions.xaxis,
                  categories: data.map((el) =>
                    formatDateByGroup(el.series, groupBy)
                  ),
                },
              } as any
            }
            type="bar"
            width="100%"
            height="100%"
          />
        )}
      </div>
    </Card>
  );
}
